/* eslint-disable no-await-in-loop */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { formatDate, isObjectEmpty } from "api/utils";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function Header({
  document,
  handleClick,
  loading,
  ownerActions,
  lookupUsers,
  handleDownload,
  disableSign,
  accessRead,
  handleClickDetails,
  handleSetLoading,
  handleRefresh,
  handleDelete,
}) {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [signatureResp, setSignatureResp] = useState({});
  const [openMenu, setOpenMenu] = useState(null);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, []);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleUngroup = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.updateDocumentContainer(
        {
          documentContainerId: document._id,
          groupName: "",
        },
        signal
      );
      await handleRefresh();
    } catch (e) {
      console.error(e.message);
    }
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseOpenDelete = () => {
    setOpenDelete(false);
  };
  const getFrontendUrl = () => {
    const queryParameters = new URLSearchParams({
      envelopeId: document._id,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const getRegisterUrl = (email) => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-up`);
    url.search = new URLSearchParams({
      email,
    });

    return url.toString();
  };

  const checkSigningOrder = async () => {
    const myAccess = document.accessRights.filter(
      (access) => access.email === userProfile.email && access.signingOrder
    );
    if (myAccess.length > 0) {
      const order = myAccess[0].signingOrder;
      if (order) {
        const nextAccess = document.accessRights.filter(
          (access) => access.signingOrder === order + 1
        );
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: document._id,
          parentType: "CONTAINER",
          accessRightsId: nextAccess[0]._id,
          frontendUrl: getFrontendUrl(),
          populateMagicLink: "true",
          registerUrl: getRegisterUrl(nextAccess[0].email),
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };
  const handleSign = async (ebsi = false, did = false, didEbsi = false) => {
    // Deletion parameters
    const params = {
      parentId: document._id,
      parentType: "CONTAINER",
      access: document.access,
      frontendUrl: getFrontendUrl(),
    };

    if (ebsi) {
      params.blockchain = "BSV_EBSI";
    } else if (did) {
      params.blockchain = "BSV_DID";
    } else if (didEbsi) {
      params.blockchain = "BSV_DID_EBSI";
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setVariant("error");
      setMessage(e.message);
      handleSetLoading(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          handleSetLoading(false);
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setMessage(t("envelope-successfully-signed"));
          setSignatureResp(responseData);
          handleSetLoading(false);
          checkSigningOrder();
          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        handleSetLoading(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setVariant("warning");
      setMessage(e.message);
      handleSetLoading(false);
    } finally {
      handleCloseMenu();
    }
  };

  const handleSignEbsi = () => {
    handleSetLoading(true);
    handleSign(true);
  };

  const handleSignDidBSV = () => {
    handleSetLoading(true);
    handleSign(false, true, false);
  };

  const handleSignDidEbsi = () => {
    handleSetLoading(true);
    handleSign(false, false, true);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        disabled={
          document.status === "EXPIRED" ||
          document.status === "DELETED" ||
          accessRead ||
          !ownerActions
        }
        onClick={handleClickDetails}
      >
        {t("view-and-add-metadata")}
      </MenuItem>

      <MenuItem onClick={handleDownload}>{t("download-report")}</MenuItem>
      {ApiService.showDID && (
        <>
          <MenuItem
            disabled={document.status === "EXPIRED" || document.status === "DELETED" || disableSign}
            onClick={handleSignDidBSV}
          >
            {t("sign-DID-format")}
          </MenuItem>
          <MenuItem
            disabled={document.status === "EXPIRED" || document.status === "DELETED" || disableSign}
            onClick={handleSignEbsi}
          >
            {t("sign-and-publish-to-EBSI")}
          </MenuItem>
          <MenuItem
            disabled={document.status === "EXPIRED" || document.status === "DELETED" || disableSign}
            onClick={handleSignDidEbsi}
          >
            {t("sign-DID-format-publish-EBSI")}
          </MenuItem>
        </>
      )}

      <MenuItem
        disabled={document.status === "EXPIRED" || document.status === "DELETED" || !ownerActions}
        onClick={handleUngroup}
      >
        {t("ungroup")}
      </MenuItem>

      <MenuItem
        disabled={document.status === "EXPIRED" || document.status === "DELETED" || !ownerActions}
        onClick={handleOpenDelete}
      >
        {t("delete")}
      </MenuItem>
    </Menu>
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={8}>
        <MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {t("envelope-details")}
            </MDTypography>
          </MDBox>

          <MDTypography component="p" variant="button" color="text">
            {!loading &&
              !isObjectEmpty(document) &&
              (document.status === "EXPIRED" || document.status === "DELETED") && (
                <>
                  <span>{`${t("status")}: `}</span>
                  <span className="rightAlign">{document.status}</span>
                </>
              )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("creator")}: `}</span>
                <b>{lookupUsers[document.sender]}</b>
              </>
            ) : (
              <>
                <span>{`${t("creator")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <span className="rightAlign">{formatDate(document.createdAt)}</span>
              </>
            ) : (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <span className="rightAlign">{formatDate(document.updatedAt)}</span>
              </>
            ) : (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("number-of-files")}: `}</span>
                <span className="rightAlign">{document?.packages?.length}</span>
              </>
            ) : (
              <>
                <span>{`${t("number-of-files")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("envelope-id")}: `}</span>
                <span className="rightAlign" style={{ wordBreak: "break-all" }}>
                  {document._id}
                </span>
              </>
            ) : (
              <>
                <span>{`${t("envelope-id")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("envelope-hash")}: `}</span>
                <span className="rightAlign" style={{ wordBreak: "break-all" }}>
                  {document.hash}
                </span>
              </>
            ) : (
              <>
                <span>{`${t("envelope-hash")}: `}</span>
                <Skeleton
                  className="rightAlign"
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item sm={12} md={4}>
        <div style={{ float: "right", display: "inline-flex" }}>
          <Tooltip title={t("advanced-functions")}>
            <IconButton aria-label="delete" size="small" onClick={handleOpenMenu}>
              <MoreVertIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>

          {renderMenu()}
          <MDButton variant="gradient" color="primary" onClick={handleClick}>
            {t("view-document")}
          </MDButton>
        </div>
      </Grid>
      <MDSnackbar
        icon="notifications"
        title={t("sign-document")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
      <Dialog open={openDelete} onClose={handleCloseOpenDelete}>
        <DialogTitle>{t("delete-file")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("are-you-sure-you-want-to-delete-this-file")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenDelete}>{t("cancel")}</Button>
          <Button onClick={handleDelete}>{t("yes")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default Header;
