/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useNavigate, useLocation, Link, createSearchParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";
import MDAlert from "components/MDAlert";

function Email() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [requireToken, setRequireToken] = useState(false);
  const [token2FA, setToken2FA] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    ApiService.removeSessionToken();
  }, []);

  const showErrorMessage = (messageContent) => {
    if (!requireToken) {
      if (messageContent === "Invalid 2FA token!") {
        setRequireToken(true);
        setMessage("2fa qrcode secret");
        return setToken2FA("");
      }
    } else {
      return setToken2FA("");
    }
    if (messageContent === "User does not exist!") {
      return setMessage("Incorrect email or password");
    }
    if (messageContent === "Invalid password!") {
      return setMessage("Incorrect email or password");
    }
    return setMessage(messageContent);
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/email-sign-in`);
    return url.toString();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError("Invalid email address");
      showErrorMessage(`${"Invalid information provided"} ${"invalid email"}`);
      return;
    }
    try {
      const responseData = await ApiService.sendUserLoginMagicLink({
        email,
        frontendUrl: getFrontendUrl(),
      });
      setMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleSubmitTokenMaginLink = async (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(location.search);
    const params = {
      userId: queryParams.get("userId"),
      magicLinkSecret: queryParams.get("magicLinkSecret"),
      token2FA,
    };

    try {
      await ApiService.magicLinkLogin(params);
      navigate("/");
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  useEffect(() => {
    const login = async () => {
      const queryParams = new URLSearchParams(location.search);
      if (
        queryParams.get("userId") &&
        queryParams.get("magicLinkSecret") &&
        queryParams.get("redirectPath")
      ) {
        const params = {
          userId: queryParams.get("userId"),
          magicLinkSecret: queryParams.get("magicLinkSecret"),
        };

        try {
          await ApiService.magicLinkLogin(params);
          navigate(queryParams.get("redirectPath"));
          return;
        } catch (e) {
          showErrorMessage(e.message);
        }
      }
      if (queryParams.get("userId") && queryParams.get("magicLinkSecret")) {
        const params = {
          userId: queryParams.get("userId"),
          magicLinkSecret: queryParams.get("magicLinkSecret"),
        };

        try {
          await ApiService.magicLinkLogin(params);
          navigate("/");
        } catch (e) {
          showErrorMessage(e.message);
        }
      }
      if (queryParams.get("redirectPath")) {
        navigate({
          pathname: "/authentication/sign-in",
          search: createSearchParams({
            redirectPath: queryParams.get("redirectPath"),
          }).toString(),
        });
      }
      if (queryParams.get("authToken")) {
        localStorage.setItem(ApiService.localStorageSessionTokenKey, queryParams.get("authToken"));
        navigate("/add-file");
      }
    };
    login();
  }, []);

  return (
    <IllustrationLayout
      title="Sign In With Email"
      description="Enter your email to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        {message.length > 0 && (
          <MDBox mb={2}>
            <MDTypography variant="caption" color="warning">
              {message}
            </MDTypography>
          </MDBox>
        )}
        {!requireToken && (
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => {
                setMessage("");
                setEmail(e.target.value);
              }}
              error={emailError.length > 0}
              helperText={emailError}
            />
          </MDBox>
        )}
        {requireToken && (
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="2FA"
              fullWidth
              value={token2FA}
              onChange={(e) => {
                setMessage("");
                setToken2FA(e.target.value);
              }}
            />
          </MDBox>
        )}
        <MDTypography
          component={Link}
          to="/authentication/reset-password-email"
          variant="button"
          color="primary"
          fontWeight="medium"
          textGradient
        >
          Forgot password?
        </MDTypography>

        <MDAlert color="light" mt={2}>
          <MDTypography color="primary" variant="caption">
            Sign in with
          </MDTypography>
          <MDTypography
            ml={1}
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            color="primary"
            fontWeight="medium"
            textGradient
          >
            email and password
          </MDTypography>
        </MDAlert>
        {requireToken ? (
          <MDBox mt={2} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmitTokenMaginLink}
            >
              sign in
            </MDButton>
          </MDBox>
        ) : (
          <MDBox mt={2} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              sign in with email
            </MDButton>
          </MDBox>
        )}
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Email;
