import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import image from "assets/images/image.png";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ApiService from "api/ApiService";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import UploadFile from "./components/UploadFile";
import PDFViewer from "./PDFViewer";

function AddFilePage() {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState({});
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState("");
  const [fileRemoved, setFileRemoved] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [docType, setDocType] = useState("");
  const [pic, setPic] = useState("");
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSetLoading = (bool) => {
    setLoading(bool);
  };

  const handleInputDisabled = (bool) => {
    setShowRemove(bool);
  };

  const handleSetFile = (base64File = "", file, type) => {
    if (type === "picture") {
      setPic(file);
      setFile(null);
      setBase64("");
      return setDocType(type);
    }
    if (type === "report") {
      setReport(base64File);
      return setDocType(type);
    }
    setFile(file);
    setBase64(base64File);
    if (file.type === "application/pdf") {
      return setDocType("pdfFile");
    }
    return setDocType(type);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setBase64("");
    setPic("");

    setFileRemoved(true);
  };

  const handleSetRemovefile = (bool) => {
    setFileRemoved(bool);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
  }, []);

  /*   useEffect(() => {
    const getFolders = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        const myAddress = await ApiService.getPublicKey(
          {
            serviceType: "SDC",
            keyType: "MASTER",
          },
          signal
        );
        const params = {
          sortBy: "createdAt:desc",
          populateSignatures: true,
          populateAccessRights: true,
        };

        params.receiver = myAddress.address;
        params.filters = JSON.stringify(
          !ApiService.production
            ? {
                $and: [
                  { "flags.name": "signOnChain" },
                  { "flags.name": { $ne: "bin" } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              }
            : {
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              }
        );

        const myDocuments = await ApiService.getSendData(params, signal);
        const gN1 = myDocuments
          .filter((f) => f.groupName !== undefined)
          .map((file) => file.groupName);

        const envelopeParams = {
          sortBy: "asc",
          populateSignatures: true,
          populateAccessRights: true,
        };

        const responseData = await ApiService.getDocumentContainers(envelopeParams, signal);
        const gN2 = responseData
          .filter((f) => f.groupName !== undefined)
          .map((file) => file.groupName);

        const newArray = gN1.concat(gN2);
        const setFolders = new Set(newArray);
        localStorage.setItem("folders", JSON.stringify([...setFolders]));
      } catch (e) {
        console.error(e);
      }
    };
    const folders = JSON.parse(localStorage.getItem("folders"));

    if (folders === null || folders.length === 0) {
      getFolders();
    }
  }, []); */

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <LinearProgress sx={{ overflow: "hidden" }} />}

      <MDBox mt={4} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={5}>
            <Card id="add-file">
              <UploadFile
                profile={userProfile}
                handleAction={handleSetFile}
                fileRemoved={fileRemoved}
                handleSetRemovefile={handleSetRemovefile}
                handleInputDisabled={handleInputDisabled}
                handleRemoveFile={handleRemoveFile}
                handleSetLoading={handleSetLoading}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            {file === null && pic.length === 0 ? (
              <MDBox sx={{ textAlign: "center", display: { xs: "none", md: "block" } }}>
                <MDTypography variant="h4" fontWeight="bold">
                  {t("scan-sign-and-store-your-documents")}
                </MDTypography>
                <MDBox pt={5}>
                  <MDTypography variant="body2">
                    {t(
                      "a-fast-easy-and-secure-way-to-scan-sign-and-store-your-documents-on-the-public-blockchain-in-the-most-secure-legal-and-efficient-way"
                    )}
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="img"
                  src={image}
                  alt={t("uploaded-image")}
                  borderRadius="lg"
                  shadow="sm"
                  height="auto"
                  position="relative"
                  maxWidth="100%"
                  zIndex={10}
                  mt={10}
                />
              </MDBox>
            ) : (
              <>
                {!showRemove && (
                  <div>
                    <Tooltip title={t("remove-file")} placement="top">
                      <MDButton
                        color="primary"
                        size="small"
                        iconOnly
                        onClick={() => handleRemoveFile()}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </Tooltip>
                  </div>
                )}
                {/*   <div style={{ opacity: "0" }}>
                  <Tooltip title={t("zoom-in")} placement="top">
                    <MDButton variant="outlined" color="info" onClick={handleZoomIn} disabled>
                      <Icon>add</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title={t("zoom-out")} placement="top">
                    <MDButton variant="outlined" color="info" onClick={handleZoomOut} disabled>
                      <Icon>remove</Icon>
                    </MDButton>
                  </Tooltip>
                </div> */}

                {file?.type === "application/pdf" && docType === "pdfFile" && (
                  // <PDFPreview file={file} scale={scale} />
                  <PDFViewer file={base64} />
                )}
                {docType === "report" && <PDFViewer file={report} />}
                {file?.type.startsWith("image") && docType !== "report" && (
                  <MDBox
                    component="img"
                    src={URL.createObjectURL(file)}
                    alt={t("uploaded-image")}
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="auto%"
                    position="relative"
                    zIndex={10}
                    mb={2}
                  />
                )}
                {docType === "picture" && (
                  <MDBox
                    component="img"
                    src={pic}
                    alt={t("uploaded-image")}
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="auto%"
                    position="relative"
                    zIndex={10}
                    mb={2}
                  />
                )}
                {/* {isMobile && (
                  <MDBox mt={1} mb={1}>
                    <MDTypography variant="caption">
                      {t("please-use-a-web-browser-to-open-the-document")}
                    </MDTypography>
                  </MDBox>
                )} */}
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddFilePage;
