/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

function OrderSummary({ document, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {t("document-blockchain-info")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          TXID
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="caption" fontWeight="medium">
            {!loading ? (
              `${document.transactionId?.slice(0, 5)}...`
            ) : (
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
            )}
          </MDTypography>
          {!loading && (
            <Tooltip title={t("see-transaction-in-blockchain")}>
              <MDButton
                component={Link}
                href={`https://whatsonchain.com/tx/${document.transactionId}`}
                variant="text"
                color="primary"
                target="_blank"
                rel="noreferrer"
              >
                <Icon>link</Icon>
              </MDButton>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {t("hash")}
        </MDTypography>
        <MDBox ml={1}>
          <Tooltip title={document.hash || ""}>
            <MDTypography variant="caption" fontWeight="medium">
              {!loading ? (
                `${document.hash?.slice(0, 5)}...`
              ) : (
                <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
              )}
            </MDTypography>
          </Tooltip>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {t("status")}
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="caption" fontWeight="medium">
            {!loading ? (
              document.transactionStatus
            ) : (
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
            )}
          </MDTypography>
        </MDBox>
      </MDBox>
      {document.blockchain === "BSV_EBSI" && document.secondaryTransactionStatus === "WRITTEN" && (
        <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            EBSI TXID
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="caption" fontWeight="medium">
              {!loading ? (
                `${document.secondaryTransactionId?.slice(0, 5)}...`
              ) : (
                <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
              )}
            </MDTypography>
            {!loading && (
              <Tooltip title={t("see-transaction-on-EBSI")}>
                <MDButton
                  component={Link}
                  href={`https://api-pilot.ebsi.eu/timestamp/v3/timestamps/${document.secondaryTransactionId}`}
                  variant="text"
                  color="primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon>link</Icon>
                </MDButton>
              </Tooltip>
            )}
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default OrderSummary;
