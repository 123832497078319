/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, Toolbar } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { isMobile } from "react-device-detect";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function PDFViewer({ file }) {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    Print: () => <></>,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    ShowProperties: () => <></>,
  });

  const renderToolbar = () => (
    <>
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    </>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar: () => [],
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      {/*  <div
        style={{
          height: "750px",
        }}
      >
        <Viewer fileUrl={file} plugins={[toolbarPluginInstance, defaultLayoutPluginInstance]} />
      </div> */}
      <div
        className="rpv-core__viewer"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            padding: "0.25rem",
            borderRadius: "0.75rem 0.75rem 0 0",
          }}
        >
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        </div>
        <div
          style={{
            height: "750px",
          }}
        >
          <Viewer
            fileUrl={file}
            plugins={[toolbarPluginInstance, defaultLayoutPluginInstance]}
            defaultScale={isMobile ? 0.6 : 1}
          />
        </div>
      </div>
    </Worker>
  );
}

export default PDFViewer;
