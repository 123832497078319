/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

function OrderSummary({ document, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {t("envelope-content")}
        </MDTypography>
      </MDBox>

      {document.packages?.map((pac) => {
        const queryParameters = new URLSearchParams({
          data_id: pac.data_id,
          sender: pac.sender,
          receiver: pac.receiver,
          hash: pac.hash,
        }).toString();
        return (
          <MDBox key={pac._id} component="li" display="flex" alignItems="center" mb={2}>
            <MDBox
              key={`${pac._id}-${pac._id}`}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MDTypography variant="button" fontWeight="medium">
                {pac.title}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                {pac.transactionId.slice(0, 5)}
              </MDTypography>
            </MDBox>
            <MDBox ml="auto" key={`${pac._id}-${pac._id}-link`}>
              <MDButton
                component={Link}
                href={`/documents/details?${queryParameters}`}
                variant="text"
                color="primary"
                target="_blank"
              >
                <Icon>link</Icon>
              </MDButton>
            </MDBox>
          </MDBox>

          /*   <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
            <MDButton
              component={Link}
              href={`/documents/details?${queryParameters}`}
              variant="text"
              color="primary"
              target="_blank"
              style={{ paddingLeft: "0px" }}
            >
              <MDTypography variant="button" fontWeight="regular" color="text">
                {pac.title}
              </MDTypography>
            </MDButton>
            <MDBox ml={1}>
              {!loading && (
                <Tooltip title={t("see-transaction-in-blockchain")}>
                  <MDButton
                    component={Link}
                    href={`https://whatsonchain.com/tx/${pac.transactionId}`}
                    variant="text"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MDTypography variant="caption" fontWeight="medium">
                      {!loading ? (
                        `${pac.transactionId?.slice(0, 5)}...`
                      ) : (
                        <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
                      )}
                    </MDTypography>
                  </MDButton>
                </Tooltip>
              )}
            </MDBox>
          </MDBox> */
        );
      })}

      {document.blockchain === "BSV_EBSI" && document.secondaryTransactionStatus === "WRITTEN" && (
        <MDBox display="flex" justifyContent="flex-start" alignItems="baseline" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            EBSI TXID
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="caption" fontWeight="medium">
              {!loading ? (
                `${document.secondaryTransactionId?.slice(0, 5)}...`
              ) : (
                <Skeleton variant="text" sx={{ width: "100px", fontSize: "1.25rem" }} />
              )}
            </MDTypography>
            {!loading && (
              <Tooltip title={t("see-transaction-on-EBSI")}>
                <MDButton
                  component={Link}
                  href={`https://api-pilot.ebsi.eu/timestamp/v3/timestamps/${document.secondaryTransactionId}`}
                  variant="text"
                  color="primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon>link</Icon>
                </MDButton>
              </Tooltip>
            )}
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default OrderSummary;
