/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useEffect, createRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ApiService from "api/ApiService";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import PDFViewer from "layouts/pages/add-file/PDFViewer";
import MDBox from "components/MDBox";
import { isObjectEmpty, formatDate } from "api/utils";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import MDAlert from "components/MDAlert";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import moment from "moment";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import OrderSummary from "./components/OrderSummary";
import Recipients from "./components/Recipients";
import Signatures from "./components/Signatures";

function EnvelopeDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fileInput = createRef();

  const location = useLocation();
  const [documentDetails, setDocumentDetails] = useState({});
  const [showDocument, setShowDocument] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showReminderDetails, setShowReminderDetails] = useState(false);
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [pdfReport, setPdfReport] = useState("");
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [showOwnerActions, setShowOwnerActions] = useState(true);
  const [SDCAddress, setSDCAddress] = useState("");
  const [timeDuration, setTimeDuration] = useState(1);
  const [timeValue, setTimeValue] = useState("year(s)");
  const [fileTime, setFileTime] = useState(moment().add(7, "days"));
  const [title, setTitle] = useState("");
  const [profile, setProfile] = useState({});
  const [packageMetadata, setPackageMetadata] = useState("");
  const [packageMetadataName, setPackageMetadataName] = useState("");
  const [packageMetadataType, setPackageMetadataType] = useState(ApiService.metadataTypes.string);
  const [lookupUsers, setLookupUsers] = useState({});
  const [uploadNotification, setShowUploadNotification] = useState(false);
  const [openExtendEolModal, setOpenExtendEolModal] = useState(false);
  const [disableSign, setDisableSign] = useState(true);
  const [pdfPages, setPdfPages] = useState(1);
  const [metadataModal, setOpenMetadataModal] = useState(false);
  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [accessRead, setAccessRead] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [verifyHashResponse, setVerifyHashResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const [reminderEmail, setReminderEmail] = useState("");
  const [reminderSubject, setReminderSubject] = useState("");
  const [reminderMessage, setReminderMessage] = useState("");
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderReceiversList, setReminderReceiversList] = useState([]);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [updateSubject, setUpdateSubject] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [updatescheduleActivationDate, setUpdatescheduleActivationDate] = useState(null);
  const [updateReceivers, setUpdateReceivers] = useState([]);
  const [updateEmail, setUpdateEmail] = useState("");

  const [openEditReminder, setOpenEditReminder] = useState(false);
  const [editReminder, setEditReminder] = useState({});
  const [deleteReminderResp, setDeleteReminderResp] = useState({});
  const [editReminderResp, setEditReminderResp] = useState({});
  const [addReminderResp, setAddReminderResp] = useState({});

  const [userEmails, setUserEmails] = useState([]);

  useEffect(() => {
    const apiOperations = async () => {
      try {
        const resp = await ApiService.getUsersList();
        setUserEmails(resp.emails);
      } catch (e) {
        console.error(e.message);
      }
    };
    apiOperations();
  }, []);

  const handleChangeFileTime = (e) => {
    setTimeDuration(e.target.value);
  };
  const handleChangeTimeValue = (e, value) => {
    setTimeValue(value);
  };
  useEffect(() => {
    if (timeValue === t("days")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "days"));
    }
    if (timeValue === t("weeks")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "weeks"));
    }
    if (timeValue === t("months")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "months"));
    }
    if (timeValue === t("years")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "years"));
    }
  }, [timeDuration, timeValue]);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShowDocument(true);
  };

  const handleClickDetails = () => {
    setShowDetails(true);
  };

  const handleHideClickDetails = () => {
    setShowDetails(false);
  };
  const handleClickReminderDetails = () => {
    setShowReminderDetails(true);
  };
  const handleHideClickReminderDetails = () => {
    setShowReminderDetails(false);
  };
  const handleHideDocument = () => {
    setShowDocument(false);
  };

  const handleSetReminderEmail = (e) => {
    const validEmail = isEmail(e.target.value);
    if (!validEmail) {
      setEmailErrorMessage(t("invalid-email-address"));
    } else {
      setEmailErrorMessage("");
    }
    setReminderEmail(e.target.value);
  };

  const handleSetReminderMessage = (e) => {
    setReminderMessage(e.target.value);
  };
  const handleSetReminderSubject = (e) => {
    setReminderSubject(e.target.value);
  };
  const handleSaveReminderReceiver = () => {
    if (reminderEmail.length === 0) {
      setShow(true);
      setMessage(t("email-address-required"));
      setVariant("error");
      return;
    }
    const validEmail = isEmail(reminderEmail);
    if (!validEmail) {
      setEmailErrorMessage(t("invalid-email-address"));
      return;
    }
    setEmailErrorMessage("");

    setReminderReceiversList([...reminderReceiversList, reminderEmail]);
    setReminderEmail("");
  };
  const handleSaveUpdateReminder = () => {
    if (updateEmail.length === 0) {
      setShow(true);
      setMessage(t("email-address-required"));
      setVariant("error");
      return;
    }
    const validEmail = isEmail(updateEmail);
    if (!validEmail) {
      setEmailErrorMessage(t("invalid-email-address"));
      return;
    }
    setEmailErrorMessage("");

    setUpdateReceivers([...updateReceivers, updateEmail]);
    setUpdateEmail("");
  };

  const handleRemoveReminderReceiver = (email) => {
    setReminderReceiversList(reminderReceiversList.filter((recEmail) => !(recEmail === email)));
  };

  const handleRemoveUpdateReminderReceiver = (email) => {
    setUpdateReceivers(updateReceivers.filter((rec) => !(rec === email)));
  };
  const handleOpenAddReminderModal = () => {
    setOpenReminderModal(true);
  };
  const handleCloseAddReminderModal = () => {
    setOpenReminderModal(false);
  };

  const handleAddReminder = async () => {
    try {
      const resp = await ApiService.addReminder({
        parentId: documentDetails._id,
        parentType: "PACKAGE",
        enabled: "true",
        scheduleType: "ONCE",
        scheduleActivationDate: reminderDate,
        subject: reminderSubject,
        message: [reminderMessage],
        receivers: reminderReceiversList.map((email) => ({
          type: "EMAIL",
          receiver: email,
        })),
      });
      setShow(true);
      setTitle(t("add-reminder"));
      setVariant("primary");
      setMessage(t("reminder-successfully-send"));
      setAddReminderResp(resp);
      handleCloseAddReminderModal();
    } catch (e) {
      console.error(e);
    }
  };

  // Retrieve package search parameters
  const query = new URLSearchParams(location.search);
  const packageParams = {
    envelopeId: query.get("envelopeId"),
  };

  const queryParameters = new URLSearchParams({
    envelopeId: packageParams.envelopeId,
  }).toString();

  useEffect(() => {
    const getUserSignature = async () => {
      try {
        await ApiService.readUserDocument({
          userId: profile._id,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.signature,
        });
      } catch (e) {
        setShowUploadNotification(true);
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getUserSignature();
  }, [profile]);

  useEffect(() => {
    const getUsers = async () => {
      const responseData = await ApiService.getAllUsers({});
      const users = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of responseData) {
        users[entry.address] = entry.user.email;
      }
      setLookupUsers(users);
    };
    getUsers();
  }, []);

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/envelope/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleDownloadDocument = () => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = documentDetails.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const fileParams = {
        data_id: documentDetails.data_id,
        hash: documentDetails.hash,
        sender: documentDetails.sender,
        receiver: documentDetails.receiver,
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(fileParams, signal);
      } catch (e) {
        console.error("Error downloading package");
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
      }
    };
    handlePrepareFileDownload();
  };

  const handleDownloadReport = (download = true) => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(reportParams, signal);

      const checkConfirmed = () => {
        if (documentDetails.accessRights.length === 0 && documentDetails.signatures.length > 0) {
          return t("confirmed");
        }
        const signSet = new Set();
        documentDetails.signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

        const accessSet = new Set();
        documentDetails.accessRights
          .filter((access) => access.type === "SIGN")
          .map((acc) => accessSet.add(acc.email));

        if (accessSet.size === 0 && signSet.size > 0) {
          return t("confirmed");
        }
        if (
          (signSet.size === accessSet.size &&
            !accessSet.has(profile.email) &&
            !signSet.has(profile.email) &&
            documentDetails.accessRights.filter((acc) => acc.email === profile.email).length ===
              0) ||
          signSet.size === accessSet.size + 1
        ) {
          return t("confirmed");
        }
        return "";
      };

      if (download) {
        const titlePrefix = checkConfirmed();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(responseData);
        a.download = `${documentDetails.title.replace(/"/g, "").replace(/\.pdf/gi, "")}${
          titlePrefix.length > 0 ? ` - ${titlePrefix}.pdf` : ".pdf"
        }`;
        a.click();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(responseData);
        reader.onloadend = function () {
          const base64data = reader.result;
          setPdfReport(base64data);
        };
      }
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentDetails._id,
        parentType: "CONTAINER",
        frontendUrl: getFrontendUrl(),
        creatorName: "SignOnChain",
        documentTitle: "SignOnChain Envelope Report",
        reportTitle: "report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(reportParams, signal);
      } catch (e) {
        console.error("Error downloading package");
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
      }
    };
    handlePrepareFileDownload();
  };

  const handleDeleteFile = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    // delete container
    try {
      responseData = await ApiService.deleteDocumentContainer(
        {
          documentContainerId: documentDetails._id,
        },
        signal
      );
      setTitle(t("delete-envelope"));
      setShow(true);
      setVariant("info");
      setMessage(t("deleted-envelope"));
    } catch (e) {
      console.error(e);
      setShow(true);
      setTitle(t("delete-envelope"));
      setVariant("warning");
      setMessage(e.message);
      return;
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("delete-envelope"));
          setVariant("warning");
          setMessage(t("failed"));
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          navigate("/documents");
        }
      }
      if (signal.aborted) {
        setShow(true);
        setTitle(t("delete-envelope"));
        setVariant("warning");
        setMessage(t("aborted"));
      }
    } catch (e) {
      console.error(e);
      setShow(true);
      setTitle(t("delete-envelope"));
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const handleRefresh = useCallback(
    async (returnData = undefined, signal = undefined) => {
      let documentData;
      const query = new URLSearchParams(location.search);
      const packageParams = {
        documentContainerId: query.get("envelopeId"),
      };

      if (!returnData) {
        setLoadingDetails(true);
        try {
          documentData = await ApiService.getDocumentContainer(packageParams, signal);
        } catch (e) {
          console.error(e);
          setShow(true);
          setTitle(t("envelope-details"));
          setVariant("warning");
          setMessage(e.message);
          setShowError(true);
        } finally {
          setLoadingDetails(false);
        }
      } else {
        documentData = returnData;
      }

      setDocumentDetails(documentData);
      setFileTime(moment(documentData.endOfLife).add(1, "years"));

      const { address } = await ApiService.getPublicKey({}, signal);
      setSDCAddress(address);
      setShowOwnerActions(address === documentData.sender);
    },
    [location.search, editReminderResp, deleteReminderResp, addReminderResp]
  );

  useEffect(() => {
    !isObjectEmpty(documentDetails) && handleDownloadReport(false);
  }, [documentDetails]);

  const handleDeleteAccess = async (id) => {
    const params = {
      parentId: documentDetails._id,
      parentType: "CONTAINER",
      accessRightsId: id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.deleteAccessRights(params, signal);
      setShow(true);
      setTitle(t("file-access"));
      setVariant("info");
      setMessage(t("removed-access"));
    } catch (e) {
      setShow(true);
      setTitle(t("file-access"));
      setVariant("warning");
      setMessage(e.message);
    }
    await handleRefresh();
  };

  const getRegisterUrl = (email) => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-up`);
    url.search = new URLSearchParams({
      email,
    });

    return url.toString();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, signal)); //  todo
      } catch (e) {
        console.error(e);
        setShow(true);
        setTitle(t("file-access"));
        setVariant("warning");
        setMessage(e.message);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [handleRefresh]);

  const checkSigningOrder = async () => {
    const myAccess = documentDetails.accessRights.filter(
      (access) => access.email === profile.email && access.signingOrder
    );
    if (myAccess.length > 0) {
      const order = myAccess[0].signingOrder;
      if (order) {
        const nextAccess = documentDetails.accessRights.filter(
          (access) => access.signingOrder === order + 1
        );
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: documentDetails._id,
          parentType: "CONTAINER",
          accessRightsId: nextAccess._id,
          frontendUrl: getFrontendUrl(),
          populateMagicLink: "true",
          registerUrl: getRegisterUrl(nextAccess.email),
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleSign = async () => {
    setDisableSign(true);
    setLoading(true);
    // Deletion parameters
    const params = {
      parentId: documentDetails._id,
      parentType: "CONTAINER",
      access: documentDetails.access,
      frontendUrl: getFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setTitle(t("sign-envelope"));
      setVariant("error");
      setMessage(e.message);
      setLoading(false);
      setDisableSign(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          setLoading(false);
          setDisableSign(false);
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("sign-envelope"));
          setMessage(t("envelope-successfully-signed"));
          setLoading(false);

          checkSigningOrder();

          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        setLoading(false);
        setDisableSign(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setTitle(t("sign-envelope"));
      setVariant("warning");
      setMessage(e.message);
      setLoading(false);
      setDisableSign(false);
    }
  };

  const handleOpenExtend = () => {
    setOpenExtendEolModal(true);
  };

  const [openVerifyHash, setOpenVerifyHash] = useState(false);

  const handleVerifyHash = () => {
    setOpenVerifyHash(true);
  };

  const handleCloseVerify = () => {
    setOpenVerifyHash(false);
    setUploadedFile(null);
    setLoading(false);
    setVerifyHashResponse("");
  };

  const handleVerifyDocHash = async () => {
    setLoading(true);
    let responseData;
    try {
      responseData = await ApiService.calcHashFile({
        file: uploadedFile,
      });
    } catch (e) {
      console.error(e);
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };
      const abortController = new AbortController();
      const signal = abortController.signal;
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("verify-hash"));
          setVariant("error");
          setMessage(t("error-checking-hash"));
          setLoading(false);
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setVerifyHashResponse(
            responseData.details.hash === documentDetails.hash ? t("validated") : t("no-match")
          );
          setLoading(false);
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setTitle(t("extend-eol"));
        setMessage(t("operation-aborted"));
        setLoading(false);

        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setTitle(t("extend-eol"));
      setMessage(t("error-uploading-file"));
      setLoading(false);
    }
  };

  const handleCloseExtend = () => {
    setOpenExtendEolModal(false);
  };

  const handleExtendEol = async () => {
    const params = {
      data_id: query.get("data_id"),
      hash: query.get("hash"),
      receiver: query.get("receiver"),
      ttl: Math.ceil((fileTime.endOf("day") - moment()) / 1000.0),
    };

    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.updatePackageTTL(params);
    } catch (e) {
      console.error(e.message);
      setShow(true);
      setTitle(t("extend-eol"));
      setVariant("warning");
      setMessage(e.message);
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };

      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("extend-eol"));
          setVariant("error");
          setMessage(t("error-extending-eol"));
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("extend-eol"));
          setMessage(t("successfully-extended-eol"));
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setTitle(t("extend-eol"));
        setMessage(t("operation-aborted"));
        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setTitle(t("extend-eol"));
      setMessage(t("error-uploading-file"));
    }
    handleCloseExtend();
    await handleRefresh();
  };

  useEffect(() => {
    const checkCanSign = () => {
      const onlyRead = documentDetails.accessRights.filter(
        (access) => access.email === profile.email && access.type === "READ"
      );
      if (onlyRead.length > 0) {
        return setAccessRead(true);
      }
      const disable =
        documentDetails.signatures.filter((sign) => lookupUsers[sign.sender] === profile.email)
          .length > 0;
      return setDisableSign(disable);
    };
    !isObjectEmpty(profile) && !isObjectEmpty(documentDetails) && checkCanSign();
  }, [profile, documentDetails]);

  const deletePackageMetadataAsync = (data) => {
    const handlePackageMetadataDeletion = async () => {
      // Deletion parameters
      const params = {
        parentId: documentDetails._id,
        parentType: ApiService.parentTypes.container,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
        handleRefresh();
      } catch (e) {
        console.error(e);
      }
    };

    handlePackageMetadataDeletion();
  };

  const deleteReminder = async (rem) => {
    try {
      const resp = await ApiService.deleteReminder({
        parentId: rem.parentId,
        parentType: rem.parentType,
        reminderId: rem._id,
      });
      setDeleteReminderResp(resp);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenEditReminder = (rem) => {
    setUpdateSubject(rem.subject);
    setUpdateMessage(rem.message[0]);
    setUpdatescheduleActivationDate(moment(rem.scheduleActivationDate));
    setUpdateReceivers(rem.receivers.map((rec) => rec.receiver));
    setEditReminder(rem);
    setOpenEditReminder(true);
  };

  const handleCloseEditReminder = () => {
    setOpenEditReminder(false);
  };

  const handleSetLoading = (bool) => {
    setLoading(bool);
  };

  const handleEditReminder = async () => {
    const params = {
      parentId: editReminder.parentId,
      parentType: editReminder.parentType,
      reminderId: editReminder._id,
    };

    if (updateSubject.length > 0) {
      params.subject = updateSubject;
    }
    if (updateMessage.length > 0) {
      params.message = [updateMessage];
    }
    if (updatescheduleActivationDate !== editReminder.scheduleActivationDate) {
      params.scheduleActivationDate = updatescheduleActivationDate;
    }
    if (updateReceivers.length > 0) {
      params.receivers = updateReceivers.map((rec) => ({
        type: "EMAIL",
        receiver: rec,
      }));
    }

    try {
      const resp = await ApiService.updateReminder(params);
      setEditReminderResp(resp);
      handleCloseEditReminder();
    } catch (e) {
      console.error(e);
    }
  };

  const prepareDataForTable = (metadata) =>
    metadata.map((elem) => ({
      user: `${lookupUsers[elem.user]} (${elem.user})`,
      date: formatDate(elem.date),
      type: elem.type,
      name: elem.name,
      metadata: elem.metadata,
      actions: (
        <MDBox mr={1}>
          <Tooltip title={t("delete")} placement="top">
            <MDButton
              color="warning"
              size="small"
              iconOnly
              circular
              onClick={() => {
                deletePackageMetadataAsync(elem);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      ),
    }));

  const prepareRemindersForTable = (reminders) =>
    reminders.map((elem) => ({
      sender: `${lookupUsers[elem.sender]}`,
      createdAt: formatDate(elem.createdAt),
      subject: elem.subject,
      message: elem.message[0],
      remindAt: elem.scheduleActivationDate,
      actions: (
        <>
          <MDBox mr={1}>
            <Tooltip title={t("edit")} placement="top">
              <MDButton
                color="light"
                size="small"
                iconOnly
                circular
                onClick={() => {
                  handleOpenEditReminder(elem);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
          <MDBox mr={1}>
            <Tooltip title={t("delete")} placement="top">
              <MDButton
                color="warning"
                size="small"
                iconOnly
                circular
                onClick={() => {
                  deleteReminder(elem);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        </>
      ),
    }));

  const structureDataForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("user"),
          accessor: "user",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("date"),
          accessor: "date",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("type"),
          accessor: "type",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("name"),
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("metadata"),
          accessor: "metadata",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(files),
    };
    return dataTableData;
  };

  const structureRemindersForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("sender"),
          accessor: "sender",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("created-at"),
          accessor: "createdAt",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("subject"),
          accessor: "subject",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("message"),
          accessor: "message",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("remind-at"),
          accessor: "remindAt",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareRemindersForTable(files),
    };
    return dataTableData;
  };

  const handleOpenMetadataModal = () => {
    setOpenMetadataModal(true);
  };
  const handleCloseMetadataModal = () => {
    setOpenMetadataModal(false);
  };

  const handleAddPackageMetadata = async () => {
    const packageMetadataNameParsed = packageMetadataName.trim();
    if (packageMetadataNameParsed.length === 0) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("package-metadata-name-field-empty"));
      setShow(true);
      return;
    }
    const packageMetadataParsed = packageMetadata.trim();
    if (packageMetadataParsed.length === 0) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("metadata-field-empty"));
      setShow(true);
      return;
    }
    let metadata;
    try {
      metadata =
        packageMetadataType === ApiService.metadataTypes.json
          ? JSON.parse(packageMetadataParsed)
          : packageMetadataParsed;
    } catch (e) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("invalid-metadata-fields"));
      setShow(true);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.addMetadata(
        {
          parentId: documentDetails._id,
          parentType: ApiService.parentTypes.container,
          name: packageMetadataNameParsed,
          type: packageMetadataType,
          metadata,
        },
        signal
      );
      handleCloseMetadataModal();
      handleRefresh();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <LinearProgress sx={{ overflow: "hidden" }} />}
      {showError ? (
        <MDBox my={6}>
          <MDBox sx={{ display: { xs: "none", md: "block" } }} style={{ textAlign: "center" }}>
            <MDTypography variant="h4" fontWeight="bold">
              {t("invalid-envelope-link")}
            </MDTypography>
            <MDBox pt={5}>
              <MDBox mb={3}>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  {t(
                    "the-envelope-you-are-trying-to-access-does-not-exists-There-can-be-several-reasons-to-his"
                  )}
                </MDTypography>
              </MDBox>

              <MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-envelopes-end-of-life-has-been-reached-and-envelope-has-been-purged")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("you-no-longer-have-access-to-this-envelope")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-link-you-clicked-is-incorrect")}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <>
          <MDBox my={6}>
            {showDocument ? (
              <>
                <MDBox style={{ display: "inline-flex" }}>
                  <MDBox mb={2}>
                    <MDButton variant="outlined" color="primary" onClick={handleHideDocument}>
                      {t("back-to-details")}
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} ml={1}>
                    <Tooltip
                      title={
                        accessRead
                          ? t("read-access")
                          : disableSign
                          ? t("you-already-signed-this-envelope")
                          : t("sign-envelope")
                      }
                    >
                      <span>
                        <MDButton
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.target.disabled;
                            return handleSign();
                          }}
                          disabled={accessRead || disableSign}
                        >
                          {t("sign")}
                        </MDButton>
                      </span>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                {/* {!isMobile && (
                  <div style={{ float: "right" }}>
                    <Tooltip title={t("zoom-in")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomIn}>
                        <Icon>add</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title={t("zoom-out")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomOut}>
                        <Icon>remove</Icon>
                      </MDButton>
                    </Tooltip>
                  </div>
                )} */}
                {pdfReport.length > 0 && (
                  // <PDFPreview file={pdfReport} scale={scale} handleSetPages={handleSetPages} />
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                      <PDFViewer file={pdfReport} />
                    </Grid>
                  </Grid>
                )}
                {/* {isMobile && (
                  <MDBox mt={1} mb={1}>
                    <MDTypography variant="caption">
                      {t("please-use-a-web-browser-to-open-the-document")}
                    </MDTypography>
                  </MDBox>
                )} */}
              </>
            ) : showDetails ? (
              <MDBox style={{ display: "inline-flex" }}>
                <MDBox mb={2}>
                  <MDButton variant="outlined" color="primary" onClick={handleHideClickDetails}>
                    {t("back-to-details")}
                  </MDButton>
                  <MDBox my={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={2}
                    >
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={handleOpenMetadataModal}
                      >
                        {t("add-metadata")}
                      </MDButton>
                    </MDBox>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("envelope-metadata")}
                        </MDTypography>
                      </MDBox>
                      <DataTable
                        table={structureDataForTable(
                          documentDetails.metadata.sort(
                            (a, b) => moment(b.createdAt) - moment(a.createdAt)
                          )
                        )}
                        entriesPerPage={false}
                        canSearch
                      />
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : showReminderDetails ? (
              <MDBox style={{ display: "inline-flex" }}>
                <MDBox mb={2}>
                  <MDButton
                    variant="outlined"
                    color="primary"
                    onClick={handleHideClickReminderDetails}
                  >
                    {t("back-to-details")}
                  </MDButton>
                  <MDBox my={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={2}
                    >
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={handleOpenAddReminderModal}
                      >
                        {t("add-new-reminder")}
                      </MDButton>
                    </MDBox>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("envelope-metadata")}
                        </MDTypography>
                      </MDBox>
                      <DataTable
                        table={structureRemindersForTable(
                          documentDetails.reminders.sort(
                            (a, b) => moment(b.createdAt) - moment(a.createdAt)
                          )
                        )}
                        entriesPerPage={false}
                        canSearch
                      />
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : (
              <>
                {uploadNotification && (
                  <MDAlert color="warning" dismissible mt={2}>
                    <MDTypography color="dark" variant="caption">
                      {t("please-upload-your-signature-as-an-image")}{" "}
                    </MDTypography>
                    <MDTypography
                      ml={1}
                      component={Link}
                      to="/profile/profile-overview#signature"
                      variant="button"
                      color="dark"
                      fontWeight="medium"
                      textGradient
                    >
                      {t("go-to-profile")}
                    </MDTypography>
                  </MDAlert>
                )}
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={12} lg={10}>
                    <Card>
                      <MDBox pt={2} px={2}>
                        <Header
                          document={documentDetails}
                          handleClick={handleClick}
                          handleVerifyHash={handleVerifyHash}
                          loading={loadingDetails}
                          ownerActions={showOwnerActions}
                          lookupUsers={lookupUsers}
                          pdfPages={pdfPages}
                          handleDelete={handleDeleteFile}
                          handleDownload={handleDownloadReport}
                          handleDownloadDocument={handleDownloadDocument}
                          handleClickDetails={handleClickDetails}
                          handleClickReminderDetails={handleClickReminderDetails}
                          handleSetLoading={handleSetLoading}
                          handleRefresh={handleRefresh}
                        />
                      </MDBox>
                      <Divider />
                      <MDBox pt={1} pb={3} px={2}>
                        <MDBox mb={3}>
                          <OrderInfo
                            document={documentDetails}
                            handleRefresh={handleRefresh}
                            loading={loadingDetails}
                            lookupUsers={lookupUsers}
                            disableSign={disableSign}
                            accessRead={accessRead}
                            handleSetLoading={handleSetLoading}
                          />
                        </MDBox>
                        <Divider />
                        <MDBox mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} xl={4}>
                              <Signatures
                                signatures={documentDetails?.signatures}
                                title={t("document-status")}
                                shadow={false}
                                loading={loadingDetails}
                                lookupUsers={lookupUsers}
                              />
                            </Grid>
                            <Grid item xs={12} xl={4}>
                              <Recipients
                                title={t("recipients")}
                                profiles={documentDetails?.accessRights}
                                shadow={false}
                                handleAction={handleDeleteAccess}
                                loading={loadingDetails}
                                fileDetails={documentDetails}
                                handleRefreshDoc={handleRefresh}
                                ownerActions={showOwnerActions}
                                address={SDCAddress}
                                accessRead={accessRead}
                                userEmails={userEmails}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={4}>
                              <OrderSummary document={documentDetails} loading={loadingDetails} />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </MDBox>

          <Footer />
          <MDSnackbar
            icon="notifications"
            title={title}
            color={variant}
            content={message}
            open={show}
            close={handleClose}
            dateTime=""
          />

          <Dialog open={openVerifyHash} onClose={handleCloseVerify} fullWidth maxWidth="md">
            <DialogTitle>{t("check-envelope-hash")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center" }}>
                    <div className="picture1" style={{ marginBottom: "0px" }}>
                      <label
                        htmlFor="files"
                        style={{ display: "block", color: "rgb(123, 128, 154)" }}
                      >
                        <MDTypography variant="h6" color="dark">
                          {uploadedFile?.name ? uploadedFile?.name : t("upload-or-drop-file")}
                        </MDTypography>
                        <MDTypography variant="caption" color="dark">
                          {uploadedFile?.name ? "" : t("only-pdf-files-are-supported")}
                        </MDTypography>
                      </label>
                      <input
                        style={{
                          cursor: "pointer",
                          display: "block",
                          height: "100%",
                          left: "0",
                          opacity: "0",
                          position: "absolute",
                          top: "0",
                          width: "100%",
                        }}
                        type="file"
                        id="files"
                        accept="application/pdf"
                        ref={fileInput}
                        onChange={async (e) => {
                          const selectedFile = e.target.files[0];

                          if (selectedFile) {
                            setUploadedFile(selectedFile);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {verifyHashResponse.length > 0 && (
                <MDTypography
                  sx={{ textAlign: "center" }}
                  variant="h6"
                  color={verifyHashResponse === t("validated") ? "success" : "warning"}
                >
                  {verifyHashResponse}
                </MDTypography>
              )}
              {loading && <LinearProgress sx={{ overflow: "hidden" }} />}
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseVerify}>{t("cancel")}</MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleVerifyDocHash}
              >
                {t("verify")}
              </MDButton>
            </DialogActions>
          </Dialog>

          <Dialog open={openExtendEolModal} onClose={handleCloseExtend}>
            <DialogTitle>{t("extend-eol")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {`${t("current-eol-date-is")}: ${formatDate(documentDetails.endOfLife)}`}
                  </MDTypography>
                  <br />
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {`${t("extend-eol-date-to")}: ${formatDate(fileTime)}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={7} lg={7} xl={7} style={{ paddingTop: "0px" }}>
                  <MDInput
                    type="number"
                    variant="standard"
                    value={timeDuration}
                    onChange={handleChangeFileTime}
                    fullWidth
                    label={t("")}
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={5} lg={5} xl={5}>
                  <Autocomplete
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        paddingRight: "0px!important",
                      },
                    }}
                    defaultValue={t("weeks")}
                    disableClearable
                    value={timeValue}
                    onChange={handleChangeTimeValue}
                    options={[t("days"), t("weeks"), t("months"), t("years")]}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseExtend}>{t("cancel")}</MDButton>
              <MDButton variant="gradient" color="primary" size="small" onClick={handleExtendEol}>
                {t("extend-eol")}
              </MDButton>
            </DialogActions>
          </Dialog>

          <Dialog open={metadataModal} onClose={handleCloseMetadataModal}>
            <DialogTitle>{t("add-metadata")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    type="text"
                    variant="standard"
                    value={packageMetadataName}
                    onChange={(e) => setPackageMetadataName(e.target.value)}
                    fullWidth
                    label={t("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    type="text"
                    variant="standard"
                    value={packageMetadata}
                    onChange={(e) => setPackageMetadata(e.target.value)}
                    fullWidth
                    label={t("metadata")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        paddingRight: "0px!important",
                      },
                    }}
                    defaultValue={ApiService.metadataTypes.string}
                    value={packageMetadataType}
                    disableClearable
                    onChange={(e, value) => setPackageMetadataType(value)}
                    options={[
                      ApiService.metadataTypes.json,
                      ApiService.metadataTypes.xml,
                      ApiService.metadataTypes.string,
                    ]}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseMetadataModal}>{t("cancel")}</MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleAddPackageMetadata}
              >
                {t("add-metadata")}
              </MDButton>
            </DialogActions>
          </Dialog>

          <Dialog open={openReminderModal} onClose={handleCloseAddReminderModal}>
            <DialogTitle>{t("add-new-reminder")}</DialogTitle>

            <DialogContent>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <MDBox mt={1}>
                    <MDInput
                      value={reminderSubject}
                      onChange={handleSetReminderSubject}
                      fullWidth
                      label={`${t("subject")}*`}
                      multiline
                      variant="standard"
                      inputProps={{ type: "text", autoComplete: "" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDInput
                      value={reminderMessage}
                      onChange={handleSetReminderMessage}
                      fullWidth
                      variant="standard"
                      label={`${t("message")}*`}
                      multiline
                      inputProps={{ type: "text", autoComplete: "" }}
                    />
                  </MDBox>
                  <MDBox>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        fullWidth
                        value={reminderDate}
                        onChange={(newValue) => setReminderDate(newValue)}
                        label={`${t("remind-date")}*`}
                        slotProps={{ textField: { variant: "standard" } }}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                {reminderReceiversList.map((receiver, index) => (
                  <Grid item xs={12} style={index > 0 ? { paddingTop: "0px" } : {}}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDBox mt={1}>
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {receiver}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" alignItems="center">
                        <MDBox
                          display="flex"
                          alignItems="center"
                          lineHeight={1}
                          ml={3}
                          sx={{ cursor: "pointer" }}
                        >
                          <MDButton onClick={() => handleRemoveReminderReceiver(receiver)}>
                            <Icon fontSize="small">remove</Icon>
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  sm={10}
                  lg={9}
                  xl={9}
                  mt={reminderReceiversList.length === 0 ? 1 : 0}
                >
                  <MDInput
                    style={{ paddingBottom: "3px" }}
                    variant="standard"
                    type="email"
                    value={reminderEmail}
                    error={emailErrorMessage.length > 0}
                    onChange={handleSetReminderEmail}
                    fullWidth
                    label={t("email-address")}
                    // inputProps={{ type: "email", autoComplete: "" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} lg={3} xl={3}>
                  <MDBox pt={reminderReceiversList.length === 0 ? 1 : 0}>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleSaveReminderReceiver}
                    >
                      <Icon>add</Icon>
                      {t("add")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseAddReminderModal}>{t("cancel")}</MDButton>
              <MDButton variant="gradient" color="primary" size="small" onClick={handleAddReminder}>
                {t("add-reminder")}
              </MDButton>
            </DialogActions>
          </Dialog>

          <Dialog open={openEditReminder} onClose={handleCloseEditReminder}>
            <DialogTitle>{t("edit-reminder")}</DialogTitle>

            <DialogContent>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <MDBox mt={1}>
                    <MDInput
                      value={updateSubject}
                      onChange={(e) => setUpdateSubject(e.target.value)}
                      fullWidth
                      label={`${t("subject")}*`}
                      multiline
                      variant="standard"
                      inputProps={{ type: "text", autoComplete: "" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDInput
                      value={updateMessage}
                      onChange={(e) => setUpdateMessage(e.target.value)}
                      fullWidth
                      variant="standard"
                      label={`${t("message")}*`}
                      multiline
                      inputProps={{ type: "text", autoComplete: "" }}
                    />
                  </MDBox>
                  <MDBox>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        fullWidth
                        value={updatescheduleActivationDate}
                        onChange={(newValue) => setUpdatescheduleActivationDate(newValue)}
                        label={`${t("remind-date")}*`}
                        slotProps={{ textField: { variant: "standard" } }}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                {updateReceivers.map((receiver, index) => (
                  <Grid item xs={12} style={index > 0 ? { paddingTop: "0px" } : {}}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDBox mt={1}>
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {receiver}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" alignItems="center">
                        <MDBox
                          display="flex"
                          alignItems="center"
                          lineHeight={1}
                          ml={3}
                          sx={{ cursor: "pointer" }}
                        >
                          <MDButton onClick={() => handleRemoveUpdateReminderReceiver(receiver)}>
                            <Icon fontSize="small">remove</Icon>
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                ))}

                <Grid item xs={12} sm={10} lg={9} xl={9} mt={updateReceivers.length === 0 ? 1 : 0}>
                  <MDInput
                    style={{ paddingBottom: "3px" }}
                    variant="standard"
                    value={updateEmail}
                    error={emailErrorMessage.length > 0}
                    onChange={(e) => setUpdateEmail(e.target.value)}
                    fullWidth
                    label={t("email-address")}
                    // inputProps={{ type: "email", autoComplete: "" }}
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} sm={2} lg={3} xl={3}>
                  <MDBox pt={updateReceivers.length === 0 ? 1 : 0}>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleSaveUpdateReminder}
                    >
                      <Icon>add</Icon>
                      {t("add")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseEditReminder}>{t("close")}</MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleEditReminder}
              >
                {t("edit-reminder")}
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </DashboardLayout>
  );
}

export default EnvelopeDetails;
