/* eslint-disable arrow-body-style */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { formatDateTime } from "api/utils";

function Logs({ profile }) {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleDownloadJSON = async () => {
    try {
      const params = {
        userId: profile._id,
        sortBy: "createdAt:desc",
        fromEntry: 0,
        exportJson: "true",
      };

      const responseData = await ApiService.downloadUserAuditLogs(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = `${t("audit-logs")}.json`.toLowerCase();
      a.click();
    } catch (e) {
      console.error(e);

      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const prepareData = (data) => {
    return data.map((row) => ({
      userid: row.metadata.find((mtd) => mtd.name === "User Id").value,
      type: row.type,
      action: row.metadata.find((mtd) => mtd.name === "Operation").value,
      amount: row.metadata.find((mtd) => mtd.name === "Amount").value,
      trialCredits: row.metadata.find((mtd) => mtd.name === "Trial Credits")?.value,
      description: row.message,
      createdAt: formatDateTime(row.createdAt),
      updatedAt: formatDateTime(row.updatedAt),
    }));
  };

  const handleDownloadXls = async () => {
    try {
      const params = {
        userId: profile._id,
        sortBy: "createdAt:desc",
        fromEntry: 0,
      };

      const responseData = await ApiService.getUserAuditLogs(params);

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const header = [
        "UserId",
        "Type",
        "Action",
        "Amount",
        "Trial credits",
        "Description",
        "Created at",
        "Updated at",
      ];
      const prepData = prepareData(responseData);

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [header]);
      XLSX.utils.sheet_add_json(ws, prepData, {
        origin: "A2",
        skipHeader: true,
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        cellStyles: true,
      });
      const data = new Blob([excelBuffer], { type: fileType });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `audit-logs.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);

      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  return (
    <>
      <Card id="logs">
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{t("logs")}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={{ xs: "max-content", sm: "100%" }}
          >
            <MDBox display="flex" alignItems="center">
              <MDBox textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
                <Icon fontSize="default">article</Icon>
              </MDBox>
              <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
                <MDTypography display="block" variant="button" fontWeight="regular" color="text">
                  {t("download-user-logs")}
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  {t("download-json-file-for-a-detailed-log-or-xls-for-summary")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDButton variant="outlined" color="primary" onClick={handleDownloadJSON}>
                {t("json")}
              </MDButton>
              <MDButton
                variant="outlined"
                color="primary"
                onClick={handleDownloadXls}
                style={{ marginLeft: "10px" }}
              >
                {t("xls")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("logs")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default Logs;
