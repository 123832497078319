/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Divider from "@mui/material/Divider";
import ApiService from "api/ApiService";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import DateCell from "layouts/ecommerce/orders/order-list/components/DateCell";

import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import { formatDate, isObjectEmpty } from "api/utils";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";
// import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { Typography } from "@mui/material";
import SalesTable from "examples/Tables/SalesTable";
import LinearProgress from "@mui/material/LinearProgress";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function DocumentsPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);
  const [myFiles, setMyFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  const [documentType, setDocumentType] = useState("Documents");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [myKeyAddress, setMyKeyAddress] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [lookupUsers, setLookupUsers] = useState({});
  const [containerName, setContainerName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderName, setFolderName] = useState("");
  const [availFolders, setAvailFolders] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [viewGroups, setViewGroups] = useState(false);
  const [clickedGroup, setClickedGroup] = useState([]);

  const handleChangeFolder = (event) => {
    setSelectedFolder(event.target.value);
  };
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const handleSetFilter = (docType) => {
    setDocumentType(docType);
  };

  const handleClose = () => {
    setShow(false);
  };

  const [selected, setSelected] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id, file) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    const getUsers = async () => {
      const responseData = await ApiService.getAllUsers({});
      const users = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of responseData) {
        users[entry.address] = entry.user.email;
      }
      setLookupUsers(users);
    };
    getUsers();
  }, []);

  const getQueryParams = useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;

      return params;
    },
    [location.search]
  );

  const handleRefresh = useCallback(
    async (signal = undefined, userProfile = {}) => {
      const getSendData = async (signal = undefined, queryParams) => {
        try {
          myFiles.length === 0 && setLoadingFiles(true);
          const myAddress = await ApiService.getPublicKey(
            {
              userId: queryParams.userId,
              serviceType: "SDC",
              keyType: "MASTER",
            },
            signal
          );
          setMyKeyAddress(myAddress);
          const params = {
            userId: queryParams.userId,
            sortBy: "createdAt:desc",
            populateSignatures: true,
            populateAccessRights: true,
          };

          if (documentType === "My Documents") {
            params.receiver = myAddress.address;
            params.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
                : {
                    $and: [
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
            );
          } else if (documentType === "Received Documents") {
            params.parentType = ApiService.parentTypes.package;
            params.populateParent = true;
            params.populateSignatures = true;
            params.populateAccessRights = true;
            if (!ApiService.production) {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                  { $or: [{ "flags.name": "signOnChain" }, { "flags.name": "FOOD_FORM" }] },
                ],
              });
            } else {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },

                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              });
            }
          } else if (documentType === "Bin") {
            params.receiver = myAddress.address;
            params.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [{ "flags.name": "signOnChain" }, { "flags.name": "bin" }],
                  }
                : { "flags.name": "bin" }
            );
          }

          if (queryParams.parentId) {
            params.parentId = queryParams.parentId;
          }

          let returnData;
          if (documentType === "Received Documents") {
            returnData = await ApiService.getAccessRights(params, signal);
            setMyFiles(returnData.filter((file) => file.parentId === undefined));
          } else if (documentType === "My Documents") {
            returnData = await ApiService.getSendData(params, signal);
            setMyFiles(returnData.filter((file) => file.parentId === undefined));
          } else if (documentType === "My Envelopes") {
            try {
              const params = {
                sortBy: "asc",
                populateSignatures: true,
                populateAccessRights: true,
              };

              const responseData = await ApiService.getDocumentContainers(params, signal);
              setMyFiles(responseData);
            } catch (e) {
              console.error(e);
            }
          } else if (documentType === "Received Envelopes") {
            try {
              const params = {
                parentType: ApiService.parentTypes.container,
                populateParent: "true",
                sortBy: "asc",
                populateSignatures: "true",
                populateAccessRights: "true",
              };

              const responseData = await ApiService.getAccessRights(params, signal);
              setMyFiles(responseData);
            } catch (e) {
              console.error(e);
            }
            // todo
          } else if (documentType === "Bin") {
            const myBinData = await ApiService.getSendData(params, signal);
            setMyFiles(myBinData.filter((file) => file.parentId === undefined));

            const recParams = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
              parentType: ApiService.parentTypes.package,
              populateParent: true,
            };
            recParams.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": `${userProfile._id}-remove` },
                    ],
                  }
                : { "flags.name": `${userProfile._id}-remove` }
            );
            const receivedBinData = await ApiService.getAccessRights(recParams, signal);
            const documents = myBinData.concat(receivedBinData);

            setMyFiles(documents.filter((file) => file.parentId === undefined));
          }

          if (documentType === "Documents") {
            // get all received and my except Bin
            const myDoc = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
            };
            myDoc.receiver = myAddress.address;
            myDoc.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
                : {
                    $and: [
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
            );

            const recDoc = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
            };
            recDoc.parentType = ApiService.parentTypes.package;
            recDoc.populateParent = "true";

            if (!ApiService.production) {
              recDoc.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                  { $or: [{ "flags.name": "signOnChain" }, { "flags.name": "FOOD_FORM" }] },
                ],
              });
            } else {
              recDoc.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              });
            }

            const myDocuments = await ApiService.getSendData(myDoc, signal);
            const received = await ApiService.getAccessRights(recDoc, signal);

            const documents = received.concat(
              myDocuments.map((myDoc) => ({ ...myDoc, myDocuments: true }))
            );
            setMyFiles(documents.filter((file) => file.parentId === undefined));
          }
        } catch (e) {
          console.error(e);
          setMessage(e.message);
          setVariant("error");
          setShow(true);
        } finally {
          setLoadingFiles(false);
        }
      };
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams);
    },
    [getQueryParams, documentType]
  );

  const handleRestoreFromBin = async (dataId, sender, receiver, hash) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([{ name: "signOnChain", value: true }]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      await handleRefresh();
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const [fileDeleted, setFileDeleted] = useState(false);

  const handleUpdateFlag = async (dataId, sender, receiver, hash) => {
    const { signal } = new AbortController();
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([
        { name: "signOnChain", value: true },
        { name: `${userProfile._id}-remove`, value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      setMessage(t("successfully-moved-to-bin"));
      setVariant("info");
      setShow(true);
      setMyFiles(myFiles.filter((file) => file.data_id !== dataId));
      if (clickedGroup.length > 0) {
        setClickedGroup(clickedGroup.filter((file) => file.data_id !== dataId));
      }
      setFileDeleted(true);
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const handleMoveToBin = async (dataId, sender, receiver, hash) => {
    const { signal } = new AbortController();
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([
        { name: "signOnChain", value: true },
        { name: "bin", value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      setMessage(t("successfully-moved-to-bin"));
      setVariant("info");
      setShow(true);
      setMyFiles(myFiles.filter((file) => file.data_id !== dataId));
      if (clickedGroup.length > 0) {
        setClickedGroup(clickedGroup.filter((file) => file.data_id !== dataId));
      }
      setFileDeleted(true);
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDeleteContainer = async (id) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    // delete container
    try {
      responseData = await ApiService.deleteDocumentContainer(
        {
          documentContainerId: id,
        },
        signal
      );
      setMyFiles(myFiles.filter((file) => file._id !== id));
      if (clickedGroup.length > 0) {
        setClickedGroup(clickedGroup.filter((file) => file._id !== id));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const prepareDataForTable = (files) => {
    const checkConfirmed = (signatures, accessRights, file) => {
      if (accessRights.length === 0 && signatures.length === 0) {
        return "stored";
      }
      if (accessRights.length === 0 && signatures.length > 0) {
        return "signed-owner";
      }
      const signSet = new Set();
      signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

      const accessSet = new Set();
      accessRights
        .filter((access) => access.type === "SIGN" || access.type === "INVITE")
        .map((acc) => accessSet.add(acc.email));

      if (signSet.size === 0 && accessSet.size === 0) {
        return "stored";
      }
      if (accessSet.size === 0 && signSet.size > 0) {
        return "signed-owner";
      }
      // sign and send
      if (signSet.size === accessSet.size + 1) {
        return "signed";
      }
      // owner send
      if (
        signSet.size === accessSet.size &&
        !accessSet.has(userProfile.email) &&
        !signSet.has(userProfile.email)
      ) {
        return "signed";
      }
      // owner only send, receiver view
      if (
        signSet.size === accessSet.size &&
        accessSet.has(userProfile.email) &&
        signSet.has(userProfile.email) &&
        !signSet.has(lookupUsers[file.sender])
      ) {
        return "signed";
      }

      return "not-signed";
      // eslint-disable-next-line no-else-return
    };

    return files.map((file) => {
      const queryParameters = new URLSearchParams({
        data_id: file.data_id,
        sender: file.sender,
        receiver: file.receiver,
        hash: file.hash,
      }).toString();

      let params;
      if (
        documentType === "My Envelopes" ||
        documentType === "Received Envelopes" ||
        (documentType === "Group details" && file.fileSize === undefined)
      ) {
        params = new URLSearchParams({
          envelopeId: file._id,
        }).toString();
      }
      return {
        title: JSON.stringify({ title: file.title, params: queryParameters }),
        endOfLife:
          (file.ttl === -1 || file.ttl === undefined) && file.endOfLife === undefined
            ? t("file-stored-forever")
            : file.endOfLife,
        groupName: file.groupName ? file.groupName : "",
        createdAt: file.createdAt,
        docType: file?.metadata?.find((data) => data.name === t("document-type"))?.metadata,
        confirmedStatus:
          !isObjectEmpty(file) && checkConfirmed(file?.signatures, file?.accessRights, file),
        actions: (
          <div style={{ alignItems: "baseline", display: "inline-flex" }}>
            {(documentType === "My Documents" ||
              documentType === "My Envelopes" ||
              (documentType === "Documents" && file.myDocuments === true)) && (
              <MDBox>
                <Tooltip title={t("select")} placement="top">
                  <Checkbox
                    checked={isSelected(file._id)}
                    onChange={(e) => handleClick(e, file._id, file)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Tooltip>
              </MDBox>
            )}
            {(documentType === "My Documents" ||
              documentType === "Received Documents" ||
              documentType === "Documents" ||
              documentType === "Group details") &&
              file.fileSize !== undefined && (
                <MDBox mr={1}>
                  <Tooltip title={t("view-details")} placement="top">
                    <Link to={`/documents/details?${queryParameters}`}>
                      <MDButton color="primary" size="small" iconOnly circular>
                        <Icon sx={{ fontWeight: "bold" }}>more_vert</Icon>
                      </MDButton>
                    </Link>
                  </Tooltip>
                </MDBox>
              )}
            {((documentType === "Documents" && file.sender === myKeyAddress.address) ||
              (documentType === "Group details" && file.fileSize !== undefined)) && (
              <MDBox mr={1}>
                <Tooltip title={t("move-to-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleMoveToBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Documents" && file.sender !== myKeyAddress.address && (
              <MDBox mr={1}>
                <Tooltip title={t("remove-received-document")} placement="top">
                  <MDButton
                    color="light"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleUpdateFlag(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}

            {documentType === "My Documents" && (
              <MDBox>
                <Tooltip title={t("move-to-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleMoveToBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}

            {documentType === "Received Documents" && (
              <MDBox mr={1}>
                <Tooltip title={t("remove-received-document")} placement="top">
                  <MDButton
                    color="light"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleUpdateFlag(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Bin" && (
              <MDBox mr={1}>
                <Tooltip title={t("restore-from-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleRestoreFromBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>restore</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {(documentType === "My Envelopes" ||
              documentType === "Received Envelopes" ||
              documentType === "Group details") &&
              file.fileSize === undefined && (
                <>
                  <MDBox mr={1}>
                    <Tooltip title={t("view-details")} placement="top">
                      <MDButton
                        color="primary"
                        size="small"
                        iconOnly
                        circular
                        href={`/envelope/details?${params}`}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>more_vert</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                  <MDBox>
                    <Tooltip title={t("delete")} placement="top">
                      <MDButton
                        color="error"
                        size="small"
                        iconOnly
                        circular
                        onClick={() => handleDeleteContainer(file._id)}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>delete_forever</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </>
              )}
          </div>
        ),
      };
    });
  };

  const structureDataForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("title"),
          accessor: "title",
          Cell: ({ value }) => {
            const jsonValue = JSON.parse(value);
            return (
              <>
                {!(documentType === "My Envelopes" || documentType === "Received Envelopes") ? (
                  <Link to={`/documents/details?${jsonValue.params}`}>
                    <DefaultCell value={jsonValue.title} />
                  </Link>
                ) : (
                  <DefaultCell value={jsonValue.title} />
                )}
              </>
            );
          },
        },
        {
          Header: t("group"),
          accessor: "groupName",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("EOL"),
          accessor: "endOfLife",

          Cell: ({ value }) => <DateCell value={value} />,
        },
        {
          Header: t("created-at"),
          accessor: "createdAt",
          sortType: (a, b) => {
            return new Date(b) - new Date(a);
          },
          Cell: ({ value }) => <DateCell value={value} />,
        },
        {
          Header: t("confirmed-status"),
          //  accessor should be a naturally sortable primitive (number, string, date, boolean).
          accessor: "confirmedStatus",
          Cell: ({ value }) => {
            let status;
            if (value === "stored") {
              status = <StatusCell icon="save" color="success" status={t("stored")} />;
            } else if (value === "signed-owner") {
              status = <StatusCell icon="done" color="primary" status={t("signed-by-owner")} />;
            } else if (value === "signed") {
              status = <StatusCell icon="done" color="primary" status={t("signed")} />;
            } else {
              status = <StatusCell icon="replay" color="error" status={t("not-yet-signed")} />;
            }
            return status;
          },
        },
        {
          Header: t("actions"),
          accessor: "actions",
          align: "right",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(files),
    };
    return dataTableData;
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
        resp.validatedDocuments && resp.validatedEmail && (await handleRefresh(undefined, resp));
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, [handleRefresh]);

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        await ApiService.loginRequired(signal, false);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, []);

  useEffect(() => {
    const getFolders = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        const myAddress = await ApiService.getPublicKey(
          {
            serviceType: "SDC",
            keyType: "MASTER",
          },
          signal
        );
        const params = {
          sortBy: "createdAt:desc",
          populateSignatures: true,
          populateAccessRights: true,
        };

        params.receiver = myAddress.address;
        params.filters = JSON.stringify(
          !ApiService.production
            ? {
                $and: [
                  { "flags.name": "signOnChain" },
                  { "flags.name": { $ne: "bin" } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              }
            : {
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              }
        );

        const myDocuments = await ApiService.getSendData(params, signal);
        const myFilteredDocs = myDocuments.filter((f) => f.groupName !== undefined);
        const gN1 = myFilteredDocs.map((file) => file.groupName);

        const envelopeParams = {
          sortBy: "asc",
          populateSignatures: true,
          populateAccessRights: true,
        };

        const responseData = await ApiService.getDocumentContainers(envelopeParams, signal);
        const myContainers = responseData.filter((f) => f.groupName !== undefined);

        const gN2 = myContainers.map((file) => file.groupName);

        const newArray = gN1.concat(gN2);
        const setFolders = new Set(newArray);
        setAvailFolders([...setFolders]);

        const all = myFilteredDocs.concat(myContainers);
        const filteredFolders = [...setFolders]
          .filter((f) => f.length > 0)
          .map((folderName) => ({
            [folderName]: all.filter((file) => file.groupName === folderName),
          }));
        setFilteredGroups(filteredFolders);
        // localStorage.setItem("folders", JSON.stringify([...setFolders]));
      } catch (e) {
        console.error(e);
      }
    };

    // const folders = JSON.parse(localStorage.getItem("folders"));

    // if (folders === null || folders.length === 0) {
    getFolders();
    // } else {
    //   setAvailFolders(folders);
    // }
  }, []);

  // eslint-disable-next-line consistent-return
  const getTitle = (title) => {
    if (title === "Documents") {
      return t("documents");
    }
    if (title === "My Documents") {
      return t("my-documents");
    }
    if (title === "Received Documents") {
      return t("received-documents");
    }
    if (title === "My Envelopes") {
      return t("my-envelopes");
    }
    if (title === "Received Envelopes") {
      return t("received-envelopes");
    }
    if (title === "Bin") {
      return t("bin");
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleSetFilter("Documents");
          return closeMenu();
        }}
      >
        {t("documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("My Documents");
          return closeMenu();
        }}
      >
        {t("my-documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("Received Documents");
          return closeMenu();
        }}
      >
        {t("received-documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("My Envelopes");
          return closeMenu();
        }}
      >
        {t("my-envelopes")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("Received Envelopes");
          return closeMenu();
        }}
      >
        {t("received-envelopes")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("Bin");
          return closeMenu();
        }}
      >
        {t("bin")}
      </MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem
        onClick={() => {
          handleSetFilter("Documents");
          return closeMenu();
        }}
      >
        <MDTypography variant="button" color="error" fontWeight="regular">
          {t("remove-filter")}
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);

  const handleCreateAddToFolder = () => {
    if (selected.length === 0) {
      return;
    }
    setSelectedFiles(myFiles.filter((file) => selected.includes(file._id)));
    setOpenNewFolderModal(true);
  };
  const handleCloseNewFolderModal = () => {
    setOpenNewFolderModal(false);
  };

  const [openNewEnvelopeModal, setOpenNewEnvelopeModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateNewEnvelope = () => {
    if (selected.length === 0) {
      return;
    }
    setSelectedFiles(myFiles.filter((file) => selected.includes(file._id)));
    setOpenNewEnvelopeModal(true);
  };

  const handleCloseNewEnvelopeModal = () => {
    setOpenNewEnvelopeModal(false);
  };

  const handleCreateNewContainer = async () => {
    setLoading(true);
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    const params = {
      title: containerName,
      message: containerName,
      access: "PRIVATE",
      endOfLife: "-1",
    };
    let containerResp;
    try {
      containerResp = await ApiService.addDocumentContainer(params, signal);
    } catch (e) {
      setShow(true);
      setMessage(e.message);
      setVariant("error");
      setLoading(false);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedFiles.length; i++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await ApiService.updatePackageParentId(
          {
            data_id: selectedFiles[i].data_id,
            hash: selectedFiles[i].hash,
            sender: selectedFiles[i].sender,
            receiver: selectedFiles[i].receiver,
            parentId: containerResp._id,
          },
          signal
        );
        if (i === selectedFiles.length - 1) {
          setLoading(false);
          setOpenNewEnvelopeModal(false);
        }
      } catch (e) {
        console.error(e.message);
        setLoading(false);
      }
    }
    setSelected([]);
  };

  const handleCreateMoveToFolder = async () => {
    setLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (folderName.length === 0 && selectedFolder.length === 0) {
      setLoading(false);

      return;
    }

    if (documentType === "My Documents" || documentType === "Documents") {
      for (let i = 0; i < selectedFiles.length; i++) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await ApiService.updateGroupName(
            {
              data_id: selectedFiles[i].data_id,
              hash: selectedFiles[i].hash,
              sender: selectedFiles[i].sender,
              receiver: selectedFiles[i].receiver,
              groupName: folderName || selectedFolder,
            },
            signal
          );
          if (i === selectedFiles.length - 1) {
            setLoading(false);
            handleCloseNewFolderModal(false);
            // update localStorage
            if (folderName.length > 0) {
              setAvailFolders([...availFolders, folderName]);
              //   const setFolders = new Set(updatedFolders);
              //   localStorage.setItem("folders", JSON.stringify([...setFolders]));
            }
          }
        } catch (e) {
          console.error(e.message);
          setLoading(false);
        }
      }
    } else if (documentType === "My Envelopes") {
      for (let i = 0; i < selectedFiles.length; i++) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await ApiService.updateDocumentContainer(
            {
              documentContainerId: selectedFiles[i]._id,

              groupName: folderName || selectedFolder,
            },
            signal
          );
          if (i === selectedFiles.length - 1) {
            setLoading(false);
            handleCloseNewFolderModal(false);
            // update localStorage
            if (folderName.length > 0) {
              setAvailFolders([...availFolders, folderName]);
              //  const updatedFolders = availFolders.push(folderName);
              // const setFolders = new Set(updatedFolders);
              // localStorage.setItem("folders", JSON.stringify([...setFolders]));
            }
          }
        } catch (e) {
          console.error(e.message);
          setLoading(false);
        }
      }
    }
    setSelected([]);
    await handleRefresh();
  };

  const handleSetViewGroups = () => {
    setViewGroups(true);
  };

  const [viewGroupDetails, setViewGroupDetails] = useState(false);
  const handleViewClickedGroup = (e, group) => {
    e.preventDefault();
    setViewGroupDetails(true);
    setDocumentType("Group details");
    setViewGroups(false);
    setClickedGroup(group);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="primary">
            new order
          </MDButton> */}
          {viewGroups && (
            <MDBox display="flex">
              <MDBox ml={1}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    setClickedGroup([]);
                    setDocumentType("Documents");
                    setViewGroupDetails(false);
                    return setViewGroups(false);
                  }}
                >
                  {t("back-to-documents")}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {viewGroupDetails && (
            <MDBox display="flex">
              <MDBox ml={1}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    setViewGroups(false);
                    setClickedGroup([]);
                    setDocumentType("Documents");
                    return setViewGroupDetails(false);
                  }}
                >
                  {t("back-to-documents")}
                </MDButton>
              </MDBox>
              <MDBox ml={1}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    setViewGroupDetails(false);
                    setClickedGroup([]);
                    return setViewGroups(true);
                  }}
                >
                  {t("back-to-groups")}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {!viewGroups && !viewGroupDetails && (
            <MDBox display="flex">
              <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                {t("filter-documents")}
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
              <MDBox ml={1}>
                <MDButton variant="text" color="dark" onClick={handleSetViewGroups}>
                  {t("view-groups")}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
        {viewGroups && !viewGroupDetails && (
          <Grid container spacing={3}>
            {filteredGroups &&
              filteredGroups.map((group) => {
                const key = Object.keys(group)[0];

                return (
                  <Grid item xs={12} sm={3}>
                    <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
                      <DefaultInfoCard
                        icon="folder"
                        title={key}
                        description={`${group[key].length} documents`}
                        clickable
                        handleClick={(e) => handleViewClickedGroup(e, group[key])}
                      />
                    </MDBox>
                  </Grid>
                );
              })}
          </Grid>
        )}
        {(!viewGroups || viewGroupDetails) && (
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {viewGroupDetails ? t("group-details") : getTitle(documentType)}
              </MDTypography>
            </MDBox>

            <DataTable
              loading={loadingFiles}
              table={structureDataForTable(
                viewGroupDetails
                  ? clickedGroup.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
                  : myFiles.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
              )}
              entriesPerPage={false}
              canSearch
              fileDeleted={fileDeleted}
              handleEnvelope={handleCreateNewEnvelope}
              handleFolder={handleCreateAddToFolder}
              showEnvelope={
                !viewGroupDetails &&
                (documentType === "Documents" || documentType === "My Documents")
              }
              showFolders={
                !viewGroupDetails &&
                (documentType === "Documents" ||
                  documentType === "My Documents" ||
                  documentType === "My Envelopes")
              }
            />
          </Card>
        )}
      </MDBox>
      <Footer />
      <MDSnackbar
        icon="notifications"
        title={t("documents")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />

      <Dialog open={openNewFolderModal} onClose={handleCloseNewFolderModal} fullWidth maxWidth="sm">
        <DialogTitle>{t("move-to-group")}</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {availFolders.length > 0 && (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">{t("select-group")}</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={selectedFolder}
                    onChange={handleChangeFolder}
                  >
                    {availFolders
                      .filter((f) => f.length > 0)
                      .map((fld) => (
                        <FormControlLabel value={fld} control={<Radio />} label={fld} />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
              <MDInput
                type="text"
                variant="outlined"
                label={t("new-group")}
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                fullWidth
              />
            </Grid>
            {/*    <Grid item sm={12}>
              <Typography variant="h6">Contains files</Typography>
              <SalesTable
                rows={selectedFiles.map((file) => ({ title: file.title }))}
                shadow={false}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        {loading && <LinearProgress sx={{ overflow: "hidden" }} />}

        <DialogActions>
          <MDButton onClick={handleCloseNewFolderModal}>{t("close")}</MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            size="small"
            onClick={handleCreateMoveToFolder}
          >
            {t("move")}
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNewEnvelopeModal}
        onClose={handleCloseNewEnvelopeModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("create-envelope")}</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <MDInput
                type="text"
                variant="standard"
                value={containerName}
                onChange={(e) => setContainerName(e.target.value)}
                fullWidth
                label={t("name")}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6">Contains files</Typography>
              <SalesTable
                rows={selectedFiles.map((file) => ({ title: file.title }))}
                shadow={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {loading && <LinearProgress sx={{ overflow: "hidden" }} />}

        <DialogActions>
          <MDButton onClick={handleCloseNewEnvelopeModal}>{t("close")}</MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            size="small"
            onClick={handleCreateNewContainer}
          >
            {t("create")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default DocumentsPage;
