/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import ApiService from "api/ApiService";
import { isObjectEmpty } from "api/utils";
import Logs from "./components/Logs";

function Settings() {
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
  }, []);

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            {!isObjectEmpty(userProfile) && (
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header profile={userProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInfo profile={userProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword profile={userProfile} />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={3}>
                    <Accounts profile={userProfile} />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={3}>
                    <Authentication profile={userProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <Sessions profile={userProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <Logs profile={userProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <DeleteAccount profile={userProfile} />
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Settings;
