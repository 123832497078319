/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { formatDateTime, isObjectEmpty, formatDate } from "api/utils";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import PaymentMethod from "layouts/pages/account/billing/components/PaymentMethod";
import Invoices from "layouts/pages/account/billing/components/Invoices";
import BillingInformation from "layouts/pages/account/billing/components/BillingInformation";
import Transactions from "layouts/pages/account/billing/components/Transactions";
import TopUp from "layouts/pages/account/billing/components/TopUp";
import ApiService from "api/ApiService";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import Card from "@mui/material/Card";
import MDSnackbar from "components/MDSnackbar";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function Billing() {
  const [userProfile, setUserProfile] = useState({});
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [subType, setSubType] = useState("Pay per use");
  const [plan1, setPlan1] = useState(false);
  const [plan2, setPlan2] = useState(false);
  const [plan3, setPlan3] = useState(false);
  const [plan4, setPlan4] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["10", "25", "50"]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [showAllInvoices, setShowAllInvoices] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [userCurrency, setUserCurrency] = useState("");
  const [pricingLists, setPricingLists] = useState({});

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["120", "300", "600"]);
    } else {
      setPrices(["10", "25", "50"]);
    }
  };

  const [invoices, setInvoices] = useState([]);
  // const [pricingPlans, setPricingPlans] = useState({});

  // useEffect(() => {
  //   const apiCall = async () => {
  //     try {
  //       const resp = await ApiService.getPricingPlans();
  //       setPricingPlans(resp.filter((plan) => plan.name.includes("SOC")));
  //     } catch (e) {
  //       console.error(e.message);
  //     }
  //   };
  //   apiCall();
  // }, []);

  const handleCloseAdminModal = () => {
    setShowAdminModal(false);
  };

  useEffect(() => {
    const apiCall = async () => {
      try {
        const resp = await ApiService.getExhangeRates();
        setPricingLists(resp.currencyExchangeRate);
        setUserCurrency(userProfile.currency ? userProfile.currency : resp.pricingCurrency);
      } catch (e) {
        console.error(e);
      }
    };
    !isObjectEmpty(userProfile) && apiCall();
  }, [userProfile]);

  useEffect(() => {
    const getInvoices = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      const params = {
        userId: userProfile._id,
        excludePendingTopUps: "true",
        sortBy: "createdAt:desc",
      };
      try {
        setLoadingInvoice(true);
        const responseData = await ApiService.getUserBillings(params, signal);
        const userSubscriptions = userProfile.subscriptions.filter(
          (sub) => sub.type === "SOC" || sub.type === "TOP UP"
        );
        const userSubscriptionsIds = userSubscriptions.map((sub) => sub._id);
        const inv = responseData.filter((invoice) =>
          userSubscriptionsIds.includes(invoice.subscriptionId)
        );
        setInvoices(inv);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingInvoice(false);
      }
    };

    !isObjectEmpty(userProfile) && getInvoices();
  }, [userProfile]);

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        setLoadingProfile(true);
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
        const pp = resp.subscriptions.filter((sub) => sub.type === "SOC" || sub.type === "SDC")[0];
        if (pp.plan === "PER OPERATION") {
          setSubType({ ...pp, plan: "Pay per use" });
          setPlan1(true);
        } else {
          setSubType(pp);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingProfile(false);
      }
    };
    apiOperations();
  }, []);

  const handleViewAllInvoices = (e, invoices) => {
    setAllInvoices(invoices);
    setShowAllInvoices(true);
  };

  const handleDownload = async (id) => {
    try {
      const params = {
        userId: userProfile._id,
        billingId: id,
        creatorName: "SignOnChain",
        documentTitle: "SignOnChain Invoice",
        reportTitle: "Invoice",
      };

      const responseData = await ApiService.downloadBillingDocument(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = "invoice.pdf";
      a.click();
    } catch (e) {
      setMessage(e.message);
      setVariant("warning");
      setShow(true);
    }
  };

  const handleRequestPricingPlanUpdate = async () => {
    let pricingPlanName;
    if (plan1) {
      pricingPlanName = "SOC per operation";
    } else if (plan2) {
      pricingPlanName = `Small SOC ${tabValue === 0 ? "monthly" : "yearly"}`;
    } else if (plan3) {
      pricingPlanName = `Medium SOC ${tabValue === 0 ? "monthly" : "yearly"}`;
    } else if (plan4) {
      pricingPlanName = `Large SOC ${tabValue === 0 ? "monthly" : "yearly"}`;
    }

    let params;
    // todo other plans
    if (userProfile.organizations.length > 0) {
      params = {
        userId: userProfile._id,
        requestPricingPlan: true,
        parentType: "ORGANIZATION",
        parentId: userProfile.organizations[0].organization,
      };
    } else {
      params = {
        userId: userProfile._id,
        requestPricingPlan: true,
      };
    }

    if (pricingPlanName.length > 0) {
      params.name = pricingPlanName;
    }

    try {
      await ApiService.requestPricingPlanUpdate(params);
      setVariant("success");
      setMessage("Request received. We'll notify you about the status");
      setShow(true);
    } catch (e) {
      setVariant("error");
      setShow(true);
      return setMessage(e.message);
    }
    handleCloseAdminModal();
    setShow(true);
    setMessage(t("request-for-pricing-plan-successfully-sent"));
    return setVariant("success");
  };

  const handleChangePlan = () => {
    if (
      userProfile.organizations[0]?.role === "ADMIN" ||
      userProfile.organizations[0]?.role === "REGISTERED" ||
      userProfile.organizations.length === 0
    ) {
      setShowAdminModal(true);
    }
  };

  const prepareDataForTable = (invoices) =>
    invoices.map((file) => ({
      id: file._id,
      description: file.description,
      amount: `${parseFloat(file.amount * pricingLists[userCurrency]).toFixed(2)} ${userCurrency}`,
      status: file.status,
      startDate: formatDateTime(file.startDate),
      endDate: formatDateTime(file.startDate),
      actions: (
        <MDBox mr={1}>
          <Tooltip title={t("view-details")} placement="top">
            <MDButton
              color="primary"
              size="small"
              iconOnly
              circular
              onClick={() => handleDownload(file._id)}
            >
              <Icon sx={{ fontWeight: "bold" }}>download</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      ),
    }));

  const structureDataForTable = (invoices) => {
    const dataTableData = {
      columns: [
        { Header: "id", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
          Header: t("description"),
          accessor: "description",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },

        {
          Header: t("amount"),
          accessor: "amount",
          Cell: ({ value }) => <DefaultCell value={`${value}`} />,
        },
        {
          Header: t("status"),
          accessor: "status",
          Cell: ({ value }) => {
            let status;
            if (value === "PAID") {
              status = <StatusCell icon="done" color="success" status="Confirmed" />;
            } else if (value === "PENDING") {
              status = <StatusCell icon="replay" color="warning" status="Pending" />;
            } else if (value === "REFUNDED") {
              status = <StatusCell icon="replay" color="warning" status="Refunded" />;
            } else {
              status = <StatusCell icon="replay" color="dark" status="Cancelled" />;
            }
            return status;
          },
        },
        {
          Header: t("start-date"),
          accessor: "startDate",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("end-date"),
          accessor: "endDate",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "actions",
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(invoices),
    };
    return dataTableData;
  };

  const handleSelectPlan1 = () => {
    setPlan1(true);
    setPlan2(false);
    setPlan3(false);
    setPlan4(false);
  };
  const handleSelectPlan2 = () => {
    setPlan2(true);
    setPlan1(false);
    setPlan3(false);
    setPlan4(false);
  };
  const handleSelectPlan3 = () => {
    setPlan3(true);
    setPlan1(false);
    setPlan2(false);
    setPlan4(false);
  };
  const handleSelectPlan4 = () => {
    setPlan4(true);
    setPlan1(false);
    setPlan3(false);
    setPlan2(false);
  };

  const handleContactUs = () => {
    const newWindow = window.open(
      "https://signonchain.id/contact/",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <BaseLayout>
      <MDBox mt={4} mb={3}>
        {showAllInvoices ? (
          <>
            <MDButton
              style={{ marginBottom: "10px" }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setShowAllInvoices(false)}
            >
              {t("back")}
            </MDButton>

            <Card mb={3}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {t("invoices")}
                </MDTypography>
              </MDBox>
              <DataTable
                table={structureDataForTable(allInvoices)}
                entriesPerPage={false}
                canSearch
              />
            </Card>
          </>
        ) : (
          <>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} xl={6} style={{ display: "none" }}>
                      <MasterCard
                        number={4562112245947852}
                        holder="jack peterson"
                        expires="11/22"
                      />
                    </Grid>
                    <Grid container xs={12} md={5} xl={4} spacing={1}>
                      <Grid item xs={12} style={{ paddingTop: "32px", paddingLeft: "32px" }}>
                        <DefaultInfoCard
                          color="primary"
                          icon="account_balance"
                          title={t("credits")}
                          description={t("available-amount")}
                          value={
                            !isObjectEmpty(userProfile) &&
                            userProfile.credits &&
                            !isObjectEmpty(pricingLists) ? (
                              `${parseFloat(
                                parseFloat(userProfile.credits) * pricingLists[userCurrency]
                              ).toFixed(2)} ${userCurrency}`
                            ) : (
                              <Skeleton
                                variant="text"
                                sx={{
                                  width: "50px",
                                  fontSize: "inherit",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            )
                          }
                          trial={
                            !isObjectEmpty(userProfile) &&
                            userProfile.trialCredits &&
                            !isObjectEmpty(pricingLists)
                              ? `${parseFloat(
                                  parseFloat(userProfile.trialCredits) * pricingLists[userCurrency]
                                ).toFixed(2)} ${userCurrency}`
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: "32px" }}>
                        <DefaultInfoCard
                          color="primary"
                          icon="account_balance"
                          hideIcon
                          showAction={
                            !isObjectEmpty(userProfile) &&
                            (userProfile.organizations[0]?.role === "ADMIN" ||
                              userProfile.organizations[0]?.role === "REGISTERED" ||
                              userProfile.organizations.length === 0)
                          }
                          title={t("subscription-plan")}
                          description={`${t("activated-on")} ${
                            !isObjectEmpty(userProfile) &&
                            !isObjectEmpty(subType) &&
                            formatDate(subType.activationDate)
                          } ${userProfile.requestPricingPlan ? "- " : ""} ${
                            userProfile.requestPricingPlan ? t("requested-plan-update-to") : ""
                          } ${userProfile.requestPricingPlan ? userProfile.pricingPlanName : ""}`}
                          value={
                            !isObjectEmpty(userProfile) && !isObjectEmpty(subType) ? (
                              subType.plan
                            ) : (
                              <Skeleton
                                variant="text"
                                sx={{
                                  width: "50px",
                                  fontSize: "inherit",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            )
                          }
                          action={handleChangePlan}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} xl={8}>
                      <TopUp profile={userProfile} title={t("top-up")} />
                    </Grid>

                    <Grid item xs={12} md={6} xl={3} style={{ display: "none" }}>
                      <DefaultInfoCard
                        color="primary"
                        icon="paypal"
                        title="paypal"
                        description="Freelance Payment"
                        value="$455.00"
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: "none" }}>
                      <PaymentMethod />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Invoices
                    loading={loadingInvoice}
                    invoices={invoices}
                    profile={userProfile}
                    handleClick={handleViewAllInvoices}
                    handleInvoice={handleDownload}
                    userCurrency={userCurrency}
                    pricingLists={pricingLists}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <BillingInformation profile={userProfile} loading={loadingProfile} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Transactions
                    invoices={invoices}
                    loading={loadingInvoice}
                    userCurrency={userCurrency}
                    pricingLists={pricingLists}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}
      </MDBox>

      <Dialog open={showAdminModal} onClose={handleCloseAdminModal} maxWidth="xl">
        <DialogTitle>{t("change-subscription-plan")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <MDBox minWidth={{ xs: "22rem", md: "25rem" }} mx="auto" mt={6}>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={tabHandler}>
                <Tab
                  id="monthly"
                  label={
                    <MDBox py={0.5} px={2} color="inherit">
                      {t("monthly")}
                    </MDBox>
                  }
                />
                <Tab
                  id="annual"
                  label={
                    <MDBox py={0.5} px={2} color="inherit">
                      {t("annual")}
                    </MDBox>
                  }
                />
              </Tabs>
            </AppBar>
          </MDBox>
          <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color={plan1 ? "dark" : "white"}
                  badge={{ color: plan1 ? "info" : "light", label: t("pay-per-use") }}
                  specifications={[
                    { label: `${t("per-signature")}: €0.35`, includes: true },
                    { label: `${t("per-reader")}: €0.10`, includes: true },
                    {
                      label: `${t("per-document")}: €0.35 + ${t("size-and-storage-time")}`,
                      includes: true,
                    },
                    { label: t("storage-time-unlimited"), includes: true },
                  ]}
                  action={{
                    onClick: handleSelectPlan1,
                    color: "dark",
                    label: t("select"),
                  }}
                  shadow={false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color={plan2 ? "dark" : "white"}
                  badge={{ color: plan2 ? "info" : "light", label: "Small" }}
                  price={{ currency: "€", value: prices[0], type: tabValue === 0 ? "mo" : "yrly" }}
                  specifications={[
                    { label: t("up-to-documents-per-month", { numberOfDocs: 5 }), includes: true },
                    { label: t("up-to-mb-per-document", { sizeOfMb: 1 }), includes: true },
                    {
                      label: t("up-to-signatures-per-document", { numberOfSig: 4 }),
                      includes: true,
                    },
                  ]}
                  action={{
                    onClick: handleSelectPlan2,
                    color: "dark",
                    label: t("select"),
                  }}
                  shadow={false}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color={plan3 ? "dark" : "white"}
                  badge={{ color: plan3 ? "info" : "light", label: "Medium" }}
                  price={{ currency: "€", value: prices[1], type: tabValue === 0 ? "mo" : "yrly" }}
                  specifications={[
                    { label: t("up-to-documents-per-month", { numberOfDocs: 12 }), includes: true },
                    { label: t("up-to-mb-per-document", { sizeOfMb: 3 }), includes: true },
                    {
                      label: t("up-to-signatures-per-document", { numberOfSig: 6 }),
                      includes: true,
                    },
                  ]}
                  action={{
                    onClick: handleSelectPlan3,
                    color: "dark",
                    label: t("select"),
                  }}
                  shadow={false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DefaultPricingCard
                  color={plan4 ? "dark" : "white"}
                  badge={{ color: plan4 ? "info" : "light", label: "Large" }}
                  price={{ currency: "€", value: prices[2], type: tabValue === 0 ? "mo" : "yrly" }}
                  specifications={[
                    { label: t("up-to-documents-per-month", { numberOfDocs: 25 }), includes: true },
                    { label: t("up-to-mb-per-document", { sizeOfMb: 6 }), includes: true },
                    {
                      label: t("up-to-signatures-per-document", { numberOfSig: 8 }),
                      includes: true,
                    },
                  ]}
                  action={{
                    onClick: handleSelectPlan4,
                    color: "dark",
                    label: t("select"),
                  }}
                  shadow={false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="center" mt={2}>
              <Grid item xs={12} lg={4}>
                <DefaultPricingCard
                  color="white"
                  badge={{ color: "light", label: t("enterprice") }}
                  specifications={[
                    { label: t("multiple-users"), includes: true },
                    { label: t("integration-with-ms-azure-active-directory"), includes: true },
                    { label: t("custom-storage-location"), includes: true },
                  ]}
                  action={{
                    onClick: handleContactUs,
                    color: "dark",
                    label: t("contact-us"),
                  }}
                  shadow={false}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseAdminModal}>{t("cancel")}</MDButton>
          <MDButton color="primary" variant="contained" onClick={handleRequestPricingPlanUpdate}>
            {t("request-change")}
          </MDButton>
        </DialogActions>
      </Dialog>

      <MDSnackbar
        icon="notifications"
        title={t("invoices")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </BaseLayout>
  );
}

export default Billing;
