/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import Skeleton from "@mui/material/Skeleton";
import { isObjectEmpty } from "api/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { isMobile } from "react-device-detect";

function OrderInfo({
  document,
  handleRefresh,
  loading,
  lookupUsers,
  disableSign,
  accessRead,
  handleSetLoading,
}) {
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [confirmed, setConfirmed] = useState({});
  const [signatureResp, setSignatureResp] = useState({});

  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, []);

  const getFrontendUrl = () => {
    const queryParameters = new URLSearchParams({
      envelopeId: document._id,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const getRegisterUrl = (email) => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-up`);
    url.search = new URLSearchParams({
      email,
    });

    return url.toString();
  };
  const checkSigningOrder = async () => {
    const myAccess = document.accessRights.filter(
      (access) => access.email === userProfile.email && access.signingOrder
    );
    if (myAccess.length > 0) {
      const order = myAccess[0].signingOrder;
      if (order) {
        const nextAccess = document.accessRights.filter(
          (access) => access.signingOrder === order + 1
        );
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: document._id,
          parentType: "CONTAINER",
          accessRightsId: nextAccess[0]._id,
          frontendUrl: getFrontendUrl(),
          populateMagicLink: "true",
          registerUrl: getRegisterUrl(nextAccess[0].email),
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleSign = async (ebsi = false, did = false, didEbsi = false) => {
    // Deletion parameters
    const params = {
      parentId: document._id,
      parentType: "CONTAINER",
      access: document.access,
      frontendUrl: getFrontendUrl(),
    };

    if (ebsi) {
      params.blockchain = "BSV_EBSI";
    } else if (did) {
      params.blockchain = "BSV_DID";
    } else if (didEbsi) {
      params.blockchain = "BSV_DID_EBSI";
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setVariant("error");
      setMessage(e.message);
      handleSetLoading(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          handleSetLoading(false);
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setMessage(t("envelope-successfully-signed"));
          setSignatureResp(responseData);
          handleSetLoading(false);
          checkSigningOrder();
          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        handleSetLoading(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setVariant("warning");
      setMessage(e.message);
      handleSetLoading(false);
    }
  };

  useEffect(() => {
    const checkConfirmed = (signatures, accessRights) => {
      if (accessRights.length === 0 && signatures.length === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-envelope-is-stored"),
        };
      }
      if (accessRights.length === 0 && signatures.length > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-envelope-is-signed-by-me"),
        };
      }
      const signSet = new Set();
      signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

      const accessSet = new Set();
      accessRights
        .filter((access) => access.type === "SIGN" || access.type === "INVITE")
        .map((acc) => accessSet.add(acc.email));

      if (signSet.size === 0 && accessSet.size === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-envelope-is-stored"),
        };
      }
      if (accessSet.size === 0 && signSet.size > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-envelope-is-signed-by-the-owner"),
        };
      }
      if (signSet.size === accessSet.size + 1) {
        return {
          status: "signed",
          mobile: t("signed"),
          description: t("this-envelope-is-signed-and-signatures-are-written-to-blockchain"),
        };
      }
      // owner send
      if (
        signSet.size === accessSet.size &&
        !accessSet.has(userProfile.email) &&
        !signSet.has(userProfile.email)
      ) {
        return {
          status: "signed",
          mobile: t("signed"),
          description: t("this-envelope-is-signed-and-signatures-are-written-to-blockchain"),
        };
      }
      // owner only send, receiver view
      if (
        signSet.size === accessSet.size &&
        accessSet.has(userProfile.email) &&
        signSet.has(userProfile.email) &&
        !signSet.has(lookupUsers[document.sender])
      ) {
        return {
          status: "signed",
          mobile: t("signed"),
          description: t("this-envelope-is-signed-and-signatures-are-written-to-blockchain"),
        };
      }

      return {
        status: "not-signed",
        mobile: t("not-signed"),
        description: t("this-envelope-is-not-yet-fully-signed"),
      };
      // eslint-disable-next-line no-else-return
    };

    if (!isObjectEmpty(document)) {
      const resp = checkConfirmed(document?.signatures, document?.accessRights);
      setConfirmed(resp);
    }
  }, [document, signatureResp]);

  const [openFoodModal, setOpenFoodModal] = useState(false);

  const handleOpenFoodFormModal = () => {
    setOpenFoodModal(true);
  };
  const handleCloseFoodModal = () => {
    setOpenFoodModal(false);
  };

  const [intReference, setIntReference] = useState("");
  const handleChangeReference = (e) => {
    setIntReference(e.target.value);
  };

  const handleUpdateSign = async () => {
    if (intReference.length > 0) {
      const foodMetadata = document.metadata.find((elem) => elem.name === "formData");
      const parsedMetadata = JSON.parse(foodMetadata.metadata);
      parsedMetadata.intReferenceNumberRec = intReference;
      const updatedParams = {
        parentId: document._id,
        parentType: "CONTAINER",
        metadataId: foodMetadata?._id,
        metadata: parsedMetadata,
      };
      try {
        await ApiService.updateMetadata(updatedParams);
      } catch (e) {
        console.error(e);
      }
    }
    handleSign(false);
    handleCloseFoodModal();
  };

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDAvatar bgColor="info">
                <Icon fontSize="lg">mail_outline</Icon>
              </MDAvatar>
            </MDBox>
            <MDBox lineHeight={1} style={{ wordBreak: "break-word" }}>
              <MDTypography variant="h6" fontWeight="medium">
                {!loading && !isObjectEmpty(document) ? (
                  document.title
                ) : (
                  <Skeleton variant="text" sx={{ width: "100px", fontSize: "1rem" }} />
                )}
              </MDTypography>
              {!loading && !isObjectEmpty(document) && document.groupName && (
                <MDTypography variant="caption" fontWeight="medium">
                  {!loading && !isObjectEmpty(document) ? (
                    `${t("group")}: ${document.groupName}`
                  ) : (
                    <Skeleton variant="text" sx={{ width: "100px", fontSize: "1rem" }} />
                  )}
                </MDTypography>
              )}
              <MDBox mb={2}>
                <MDTypography variant="button" color="text">
                  {confirmed.description}
                </MDTypography>
              </MDBox>
              <MDBadge
                variant="gradient"
                color={
                  // eslint-disable-next-line no-nested-ternary
                  confirmed.status === "stored"
                    ? "success"
                    : confirmed.status === "signed-owner" || confirmed.status === "signed"
                    ? "primary"
                    : "error"
                }
                size="xs"
                badgeContent={isMobile ? confirmed.mobile : confirmed.description}
                container
              />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ float: "right", display: "inline-flex", alignItems: "end" }}>
            <Tooltip
              title={
                accessRead
                  ? t("read-access")
                  : disableSign
                  ? t("you-already-signed-this-document")
                  : t("sign-document")
              }
            >
              <span>
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  disabled={
                    document.status === "EXPIRED" ||
                    document.status === "DELETED" ||
                    disableSign ||
                    accessRead
                  }
                  onClick={
                    document.flags?.find((flag) => flag.name === "FOOD_FORM")
                      ? handleOpenFoodFormModal
                      : (e) => {
                          e.target.disabled = true;
                          return handleSign(false);
                        }
                  }
                >
                  {t("sign")}
                </MDButton>
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <MDSnackbar
        icon="notifications"
        title={t("sign-document")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />

      <Dialog open={openFoodModal} onClose={handleCloseFoodModal}>
        <DialogTitle>{t("receiving-food-business-operator")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>
            {t(
              "in-order-to-finish-the-registration-form-enter-the-internal-reference-number-and-sign-the-form"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="token"
            label={`II.1 ${t("internal-reference-number")}`}
            fullWidth
            value={intReference}
            variant="standard"
            onChange={handleChangeReference}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFoodModal}>{t("cancel")}</Button>
          <Button
            onClick={(e) => {
              e.target.disabled;
              return handleUpdateSign();
            }}
          >
            {t("sign")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderInfo;
