/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { formatDate, isObjectEmpty } from "api/utils";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import ApiService from "api/ApiService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MDSnackbar from "components/MDSnackbar";
import Link from "@mui/material/Link";

function Header({
  document,
  handleClick,
  handleOpenExtend,
  handleVerifyHash,
  loading,
  ownerActions,
  lookupUsers,
  pdfPages,
  accessRead,
  handleClickDetails,
  handleClickReminderDetails,
  handleDownloadDocument,
  handleDownload,
  handleSetLoading,
  handleRefresh,
  disableSign,
  handleDelete,
}) {
  const { t } = useTranslation();

  const formatSize = (fileSize) => {
    const units = ["B", "KB", "MB", "GB", "TB"];

    let l = 0;
    let n = parseInt(fileSize, 10) || 0;

    // eslint-disable-next-line no-plusplus
    while (n >= 1000 && ++l) {
      // eslint-disable-next-line operator-assignment
      n = n / 1000;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 2)} ${units[l]}`;
  };

  const [openMenu, setOpenMenu] = useState(null);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [signatureResp, setSignatureResp] = useState({});
  const [userProfile, setUserProfile] = useState({});

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handleOpenVerifyHash = () => {
    handleVerifyHash();
    handleCloseMenu();
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = {}; // await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
        setTitle(t("login-required"));
      }
    };
    apiOperations();
  }, []);

  const handleExtendEol = () => {
    handleOpenExtend();
    handleCloseMenu();
  };

  const downloadDocument = () => {
    handleDownloadDocument(true);
    handleCloseMenu();
  };
  const getFrontendUrl = () => {
    const queryParameters = new URLSearchParams({
      data_id: document.data_id,
      sender: document.sender,
      receiver: document.receiver,
      hash: document.hash,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const getRegisterUrl = (email) => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-up`);
    url.search = new URLSearchParams({
      email,
    });

    return url.toString();
  };

  const checkSigningOrder = async () => {
    const myAccess = document.accessRights.filter(
      (access) => access.email === userProfile.email && access.signingOrder
    );
    if (myAccess.length > 0) {
      const order = myAccess[0].signingOrder;
      if (order) {
        const nextAccess = document.accessRights.filter(
          (access) => access.signingOrder === order + 1
        );
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: document._id,
          parentType: "PACKAGE",
          accessRightsId: nextAccess[0]._id,
          frontendUrl: getFrontendUrl(),
          populateMagicLink: "true",
          registerUrl: getRegisterUrl(nextAccess[0].email),
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleSign = async (ebsi = false, did = false, didEbsi = false) => {
    // Deletion parameters
    const params = {
      parentId: document._id,
      parentType: "PACKAGE",
      access: document.access,
      frontendUrl: getFrontendUrl(),
    };

    if (ebsi) {
      params.blockchain = "BSV_EBSI";
    } else if (did) {
      params.blockchain = "BSV_DID";
    } else if (didEbsi) {
      params.blockchain = "BSV_DID_EBSI";
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setTitle(t("sign-document"));
      setVariant("error");
      setMessage(e.message);
      handleSetLoading(false);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
          handleSetLoading(false);
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("sign-document"));
          setMessage(t("document-successfully-signed"));
          setSignatureResp(responseData);
          handleSetLoading(false);
          checkSigningOrder();
          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
        handleSetLoading(false);
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setTitle(t("download"));

      setVariant("warning");
      setMessage(e.message);
      handleSetLoading(false);
    }
  };

  const handleSignEbsi = () => {
    handleSetLoading(true);
    handleSign(true);
  };

  const handleSignDidBSV = () => {
    handleSetLoading(true);
    handleSign(false, true, false);
  };

  const handleSignDidEbsi = () => {
    handleSetLoading(true);
    handleSign(false, false, true);
  };

  const handleUngroup = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.updateGroupName(
        {
          data_id: document.data_id,
          hash: document.hash,
          sender: document.sender,
          receiver: document.receiver,
          groupName: "",
        },
        signal
      );
      await handleRefresh();
    } catch (e) {
      console.error(e.message);
    }
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseOpenDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const [sharingUsers, setSharingUsers] = useState([]);
  const [sharingRecipients, setSharingRecipients] = useState([]);

  const getPublicShareUser = async (signal = undefined) => {
    try {
      const publicShareUser = await ApiService.getPublicShareUser(signal);
      setSharingUsers([publicShareUser]);
      setSharingRecipients([publicShareUser.address]);
    } catch (e) {
      console.error(e);
    }
  };

  const getShareFrontendUrl = () => {
    const url = new URL(`${ApiService.idFrontendURL}/published-document`);

    return url.toString();
  };

  const handleAddPackageComment = async (response) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const url = new URL(`${ApiService.idFrontendURL}/published-document`);
    url.search = new URLSearchParams({
      packageId: response.packageId,
      publicShareSecret: response.publicShareSecret,
    });

    try {
      await ApiService.addPackageComment(
        {
          userId: userProfile._id,
          data_id: document.data_id,
          hash: document.hash,
          sender: document.sender,
          receiver: document.receiver,
          comment: JSON.stringify({ publishUrl: url.toString() }),
        },
        signal
      );
    } catch (e) {
      console.error(e.message);
    }
    // handleRefresh(returnData);
  };

  const handleShare = async (event) => {
    const userId = userProfile._id;
    const title = "SOC publish";
    const message = "SOC publish";
    const ttl = -1;

    const params = {
      userId,
      data_id: document.data_id,
      hash: document.hash,
      sender: document.sender,
      receiver: document.receiver,
      shareable: "false",
      receivers: JSON.stringify(sharingRecipients),
      ttl: ttl.toString(),
      title,
      message,
      frontendUrl: getShareFrontendUrl(),
    };

    // Share package
    let responseData;
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      responseData = await ApiService.shareFile(params, signal);
    } catch (e) {
      setVariant("error");
      setShow(true);
      setTitle(t("publish-document"));
      return setMessage(t("error-publishing-document"));
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setVariant("error");
          setShow(true);
          setTitle(t("publish-document"));
          return setMessage(t("error-publishing-document"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          (responseData.status === ApiService.jobStatus.done &&
            responseData.details.blockchainTransactions.length > 0)
        ) {
          await handleAddPackageComment(responseData.details.blockchainTransactions[0]);

          setVariant("success");
          setShow(true);
          setTitle(t("publish-document"));
          handleRefresh();
          return setMessage(t("document-published"));
        }
      }
      if (signal.aborted) {
        setVariant("error");
        setShow(true);
        setTitle(t("publish-document"));
        return setMessage(t("error-publishing-document"));
      }
    } catch (e) {
      setVariant("error");
      setShow(true);
      setTitle(t("publish-document"));
      return setMessage(t("error-publishing-document"));
    } finally {
      handleCloseMenu();
    }
  };

  const handlePublish = async () => {
    await getPublicShareUser();
    await handleShare();
  };

  const getComments = () => {
    const publishLink = document.comments.filter((com) => JSON.parse(com.comment).publishUrl);
    return publishLink.length > 0 ? JSON.parse(publishLink[0].comment).publishUrl : false;
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleOpenVerifyHash}>{t("verify-hash")}</MenuItem>

      {/* <MenuItem
        disabled={
          document.status === "EXPIRED" ||
          document.status === "DELETED" ||
          accessRead ||
          !ownerActions
        }
        onClick={handleClickDetails}
      >
        {t("view-metadata")}
      </MenuItem>
      <MenuItem
        disabled={
          document.status === "EXPIRED" ||
          document.status === "DELETED" ||
          accessRead ||
          !ownerActions
        }
        onClick={handleClickReminderDetails}
      >
        {t("view-reminders")}
      </MenuItem> */}
      <MenuItem
        disabled={document.status === "EXPIRED" || document.status === "DELETED"}
        onClick={downloadDocument}
      >
        {t("download-document")}
      </MenuItem>
    </Menu>
  );

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={8}>
        <MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {t("document-details")}
            </MDTypography>
          </MDBox>

          <MDTypography component="p" variant="button" color="text">
            {!loading &&
              !isObjectEmpty(document) &&
              (document.status === "EXPIRED" || document.status === "DELETED") && (
                <>
                  <span>{`${t("status")}: `}</span>
                  <b>{document.status}</b>
                </>
              )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("creator")}: `}</span>
                <b>{lookupUsers[document.sender]}</b>
              </>
            ) : (
              <>
                <span>{`${t("creator")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <b>{formatDate(document.createdAt)}</b>
              </>
            ) : (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <b>{formatDate(document.updatedAt)}</b>
              </>
            ) : (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("number-of-pages")}: `}</span>
                <b>{pdfPages}</b>
              </>
            ) : (
              <>
                <span>{`${t("number-of-pages")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("document-id")}: `}</span>
                <b style={{ wordBreak: "break-all" }}>{document._id}</b>
              </>
            ) : (
              <>
                <span>{`${t("document-id")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("document-hash")}: `}</span>
                <b style={{ wordBreak: "break-all" }}>{document.hash}</b>
              </>
            ) : (
              <>
                <span>{`${t("document-hash")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("eol-date")}: `}</span>
                <b>
                  {document.ttl === -1 ? t("file-stored-forever") : formatDate(document.endOfLife)}
                </b>
              </>
            ) : (
              <>
                <span>{`${t("eol-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("size")}: `}</span>
                <b>{formatSize(document.fileSize)}</b>
              </>
            ) : (
              <>
                <span>{`${t("size")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item sm={12} md={4}>
        <div style={{ float: "right", display: "inline-flex" }}>
          {/* {document.ttl !== -1 && (
            <MDButton
              variant="text"
              color="primary"
              onClick={handleOpenExtend}
              disabled={!ownerActions}
            >
              {t("extend-eol")}
            </MDButton>
          )} */}
          <Tooltip title={t("advanced-functions")}>
            <IconButton aria-label="delete" size="small" onClick={handleOpenMenu}>
              <MoreVertIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {renderMenu()}
          {document.comments && document.comments.length > 0 && getComments() && (
            <Tooltip title={t("view-public-page")}>
              <Link href={getComments()}>
                <IconButton aria-label="delete" size="small" style={{ marginRight: "10px" }}>
                  <OpenInNewIcon fontSize="inherit" />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          <MDButton
            variant="gradient"
            color="primary"
            onClick={handleClick}
            disabled={document.status === "EXPIRED" || document.status === "DELETED"}
          >
            {t("view-document")}
          </MDButton>
        </div>
      </Grid>
      <Dialog open={openDelete} onClose={handleCloseOpenDelete}>
        <DialogTitle>{t("delete-file")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("are-you-sure-you-want-to-delete-this-file")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenDelete}>{t("cancel")}</Button>
          <Button onClick={handleDelete}>{t("yes")}</Button>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title={title}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </Grid>
  );
}

export default Header;
