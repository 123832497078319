/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { isObjectEmpty } from "api/utils";
import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/account/components/FormField";
import Checkbox from "@mui/material/Checkbox";
import isEmail from "validator/lib/isEmail";
import * as XLSX from "xlsx";

function Sessions({ profile, organizationProfile, handleRefresh }) {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const [openDeleteOrganizationUser, setOpenDeleteOrganizationUser] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [userToEdit, setUserToEdit] = useState({});
  const [userRole, setUserRole] = useState("EMPLOYEE");

  const handleDeleteOrganizationUser = (organizationUser) => {
    setUserToDelete(organizationUser);
    setOpenDeleteOrganizationUser(true);
  };

  const handleOpenEditModal = (user) => {
    setUserToEdit(user);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteOrganizationUser(false);
  };

  const prepareData = (data) => {
    return data.map((row) => ({
      userid: row.metadata.find((mtd) => mtd.name === "User Id").value,
      type: row.type,
      action: row.metadata.find((mtd) => mtd.name === "Operation").value,
      amount: row.metadata.find((mtd) => mtd.name === "Amount").value,
      description: row.message,
    }));
  };

  const handleDownloadXls = async (usr) => {
    try {
      const params = {
        userId: usr.user._id,
        sortBy: "createdAt:desc",
        fromEntry: 0,
      };

      const responseData = await ApiService.getUserAuditLogs(params);

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const header = ["UserId", "Type", "Action", "Amount", "Description"];
      const prepData = prepareData(responseData);

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [header]);
      XLSX.utils.sheet_add_json(ws, prepData, {
        origin: "A2",
        skipHeader: true,
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        cellStyles: true,
      });
      const data = new Blob([excelBuffer], { type: fileType });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `audit-logs-${usr.user.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);

      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const prepareDataForTable = (users) =>
    users.map((usr) => ({
      name: usr.user.name,
      email: usr.user.email,
      role: usr.role,
      actions: (
        <>
          <MDBox mr={1}>
            <Tooltip title={t("edit")} placement="top">
              <MDButton
                color="primary"
                size="small"
                iconOnly
                circular
                onClick={() => handleOpenEditModal(usr)}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
          <MDBox mr={1}>
            <Tooltip title={t("download-xls")} placement="top">
              <MDButton
                color="success"
                size="small"
                iconOnly
                circular
                onClick={() => handleDownloadXls(usr)}
              >
                <Icon sx={{ fontWeight: "bold" }}>article</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
          <MDBox mr={1}>
            <Tooltip title={t("delete")} placement="top">
              <MDButton
                color="warning"
                size="small"
                iconOnly
                circular
                onClick={() => handleDeleteOrganizationUser(usr)}
              >
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        </>
      ),
    }));

  const structureDataForTable = (users) => {
    const dataTableData = {
      columns: [
        {
          Header: t("name"),
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("email-address"),
          accessor: "email",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("role"),
          accessor: "role",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(users),
    };
    return dataTableData;
  };

  const handleAddOrganizationUser = async () => {
    const params = {
      userId: profile._id,
      organizationId: organizationProfile._id,
      organizationUserId: userToEdit.user._id,
      organizationRoleType: userRole,
    };

    try {
      await ApiService.addOrganizationUser(params);
      setMessage(t("edit-user-successfully"));
      setVariant("success");
      setShow(true);
      handleCloseEditModal();
      handleRefresh();
    } catch (e) {
      console.error(e.message);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const handleChangeUserRole = (e, value) => {
    setUserRole(value);
  };

  const handleDeleteProfile = async () => {
    try {
      await ApiService.deleteOrganizationUser({
        organizationId: organizationProfile._id,
        organizationUserId: userToDelete.user._id,
      });
      setMessage(t("user-successfully-removed-from-organization"));
      setVariant("success");
      setShow(true);
      handleCloseDeleteModal();
      handleRefresh();
    } catch (e) {
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [confirmUserPassword, setConfirmUserPassword] = useState("");
  const [validateEmailCheck, setValidateEmailCheck] = useState(true);
  const [validateDocs, setValidateDocs] = useState(true);

  const handleCreateUser = async () => {
    const name = userName.trim();
    const email = userEmail.trim();
    const password = userPassword;
    const confirmPassword = confirmUserPassword;
    const validatedEmail = validateEmailCheck.length > 0;
    const validatedDocuments = validateDocs.length > 0;

    const validName = name.length > 0;
    const validEmail = isEmail(email);
    const validPasswordLength = password.length === 0 || password.length > 6;
    const passwordsMatch = password === confirmPassword;

    const checksPassed = validName && validEmail && validPasswordLength && passwordsMatch;
    if (!checksPassed) {
      if (!validName) {
        setMessage(t("name-length-should-be-greater-than-1"));
      }
      if (!validEmail) {
        setMessage(t("email-address"));
      }
      if (!validPasswordLength) {
        setMessage(t("minimum-password-length-is-7"));
      }
      if (!passwordsMatch) {
        setMessage(t("passwords-do-not-match"));
      }
      setShow(true);
      setVariant("error");
      return;
    }
    const params = {
      name,
      email,
      validatedEmail,
      validatedDocuments,
    };
    if (password.length > 0) {
      params.password = password;
    }

    let newUser;
    try {
      newUser = await ApiService.createUserSuperuser(params);
    } catch (e) {
      console.error(e.message);
      setShow(true);
      setVariant("error");
      setMessage(e.message);
    }

    const addParams = {
      userId: profile._id,
      organizationId: organizationProfile._id,
      organizationUserId: newUser.user._id,
      organizationRoleType: userRole,
    };

    try {
      await ApiService.addOrganizationUser(addParams);
      setMessage(t("edit-user-successfully"));
      setVariant("success");
      setShow(true);
      handleCloseEditModal();
    } catch (e) {
      console.error(e.message);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }

    setOpenAddUserModal(false);
  };

  return (
    <>
      <Card id="users">
        <MDBox p={3} lineHeight={1}>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }} pb={{ xs: 3, sm: 0 }}>
            <MDBox>
              {" "}
              <MDTypography variant="h5">{t("users")}</MDTypography>
            </MDBox>

            <MDBox ml="auto" mr={0}>
              <MDButton onClick={handleOpenAddUserModal} variant="outlined" color="primary">
                {t("add-user")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <DataTable
          table={structureDataForTable(
            !isObjectEmpty(organizationProfile.users) ? organizationProfile.users : {}
          )}
          entriesPerPage={false}
          canSearch
          // fileDeleted={fileDeleted}
        />
      </Card>

      <Dialog open={openEditModal} onClose={handleCloseEditModal} maxWidth="sm" fullWidth>
        <DialogTitle>{t("edit-user")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("change-role-type")}</DialogContentText>
          <div>
            <Autocomplete
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  paddingRight: "0px!important",
                },
              }}
              defaultValue={userToEdit.role}
              disableClearable
              value={userRole}
              onChange={handleChangeUserRole}
              options={Object.values(ApiService.organizationRoleTypes)}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseEditModal}>{t("cancel")}</MDButton>
          <MDButton color="primary" variant="contained" onClick={handleAddOrganizationUser}>
            {t("edit")}
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteOrganizationUser} onClose={handleCloseDeleteModal}>
        <DialogTitle>{t("delete-profile")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("profile-deletion-process-started-0")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDeleteModal}>{t("cancel")}</MDButton>
          <MDButton color="error" variant="contained" onClick={handleDeleteProfile}>
            {t("delete-profile")}
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddUserModal} onClose={handleCloseAddUserModal}>
        <DialogTitle>{t("add-user-to-company")}</DialogTitle>
        <DialogContent>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("name")}
                  placeholder={userName || ""}
                  value={userName || ""}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("email")}
                  placeholder={userEmail || ""}
                  value={userEmail || ""}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("password")}
                  placeholder={userPassword || ""}
                  value={userPassword || ""}
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("confirm-password")}
                  placeholder={confirmUserPassword || ""}
                  value={confirmUserPassword || ""}
                  onChange={(e) => {
                    setConfirmUserPassword(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  {t("role")}
                </MDTypography>
                <Autocomplete
                  defaultValue={userToEdit.role}
                  disableClearable
                  style={{ width: "100%" }}
                  value={userRole}
                  onChange={handleChangeUserRole}
                  options={Object.values(ApiService.organizationRoleTypes)}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Checkbox
                  checked={validateEmailCheck}
                  onChange={(e) => setValidateEmailCheck(e.target.checked)}
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  {t("validated-email")}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Checkbox
                  checked={validateDocs}
                  onChange={(e) => setValidateDocs(e.target.checked)}
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  {t("validated-documents")}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseAddUserModal}>{t("cancel")}</MDButton>
          <MDButton color="primary" variant="contained" onClick={handleCreateUser}>
            {t("add-user")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title={t("users")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default Sessions;
